<template>
    <div class='sl-create-report-draft sl-mtb-20px sl-display-flex-justify-content-start'>
      <button v-on:click="renderLoadingModal" class='pl-blue-border-and-fill-text-rect-btn' type='button'>Create report draft</button>
        <div style='display:none'>
          <a id='makeCpmWordDocLink' v-bind:href="'/bluelynx_make_docs/make_word_doc_bluelynx?the_report_type=cpm&project_id=' + project_id"></a>
        </div>
    </div>
    <!-- Spinning Modal for generating word draft -->
    <spinner v-if="showLoadingModal" :modalLoadingText="loadingModalText"/>
  </template>
<script>
import spinner from '../SpinningModal.vue';

export default {
  components: {
    spinner,
  },
  data() {
    return {
      project_id: null,
      showLoadingModal: false,
      loadingModalText: '',
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const project_id = urlParams.get('id');
    this.project_id = project_id;
  },
  methods: {
    renderLoadingModal() {
      if (window.confirm('This will create a report draft in both word and pdf formats.'
      + 'Expect it to take about 4 minutes to complete. Press Ok to proceed and create draft.')) {
        // Grabs the href link from the "a tag" and re-directs the location to make word doc method
        window.location = document.getElementById('makeCpmWordDocLink').getAttribute('href');
        this.loadingModalText = 'Word document creation in progress.. Please wait';
        this.showLoadingModal = true;
      }
    },
  },
};
</script>
