<template>
  <SpinningModal v-if="showLoadingModal"/>
  <EwemImportModal v-if="showImportModal"
                    :baselineMetricId="baselineMetricId"
                    @close-modal="showImportModal=false;"
                    @import-complete="uploadComplete()"/>
  <div class='sl-mt-10px sl-mb-40px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>Energy and Water Conservative Measures</h1>
  </div>
  
  <div class='sl-mt-50px sl-mb-10px sl-flex-align-items-center'>
      <span @click='showImportModal=true;' class='sl-cursor-pointer figma-icon-link sl-bold-text sl-ml-5px'
            style='font-size: 26px;' title='Import'>+</span>
      <button @click='createEwem()' class='sl-simple-outline-btn sl-mlr-10px'>Add Rows</button>
      <input type='number' min='1' :max='maxRowsAllowed' v-model='rowsToAdd'
             class='sl-border-1px-medium-charcoal sl-w-60px sl-pl-5px sl-ptb-5px'
             id='row_number'/>
    <span class='sl-ml-10px' style='color: red;'>{{ alertMessage }}</span>
  </div>
  <form class="sl-flex-justify-content-center" :key="componentKey">
    <!-- Description Column (Fixed) -->
    <table class="sl-esg-decarbonization-table" id="sl-ewem-table">
      <thead>
        <tr class="sl-h-28px">
          <td class="sl-w-45px"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-355px sl-border-r-1px-medium-charcoal"></td>
        </tr>
        <tr>
          <td class="sl-w-45px"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-30px sl-border-l-1px-transparent"></td>
          <td class="sl-w-355px sl-border-r-1px-medium-charcoal">Description & Performance Specification</td>
        </tr>
      </thead>
      <tbody id='pl_table_body'>
        <tr v-for="ewem in ewems" 
            :id="ewem.id"
            class="sl-table__row sl-table__row--esg"
            :class="{ 'sl-border-b-1px': isLastRow(ewem) }">
          <td class="sl-w-45px">
            <span @click='toggleExpand(ewem)'
                  class="sl-cursor-pointer sl-pr-10px sl-bold-text" style="color: #005199">
              <span v-if="ewem.expand" style="font-size: 30px">-</span>
              <span v-else class="sl-font-size-20px">+</span>
            </span>
            <span class="sl-bold-text" style="margin-top: 2px;">{{ `${ewem.index_number}.` }}</span>
          </td>
          <td class='sl-w-30px sl-cursor-pointer sortable-handle'>
            <handle class='sl-mb-5px'/>
          </td>
          <td>
            <div class="sl-w-30px sl-flex-justify-align-center">
              <deleteIcon @click="deleteEwem(ewem)" transform='scale(0.7)' class="figma-icon-delete links-center"/>
            </div>
          </td>
          <td class="sl-w-355px sl-border-r-1px-medium-charcoal">
            <textarea v-model="ewem.description" column="description"
                      @change="saveEwem(ewem, $event)"
                      class="sl-w-355px sl-overflow-y-scroll sl-pl-10px"
                      :class="{ expandedTextCell: ewem.expand}">
            </textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Main Table Body -->
    <div class="sl-esg-overflow-container">
      <table class="sl-esg-decarbonization-table" id="sl-ewem-table">
        <thead>
          <tr class="sl-h-28px">
            <td class="sl-w-100px"></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"></td>
            <td class="sl-w-100px sl-border-l-1px-transparent"></td>
            <td class="sl-w-100px"></td>
            <td class="sl-w-100px sl-border-l-1px-transparent sl-white-space-no-wrap">Annual Cost Savings</td>
            <td class="sl-w-100px sl-border-l-1px-transparent"></td>
            <td class="sl-w-100px sl-white-space-no-wrap">Annual Site</td>
            <td class="sl-w-100px sl-border-l-1px-transparent">&nbspSavings</td>
            <td class="sl-w-175px"></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"></td>
            <td class="sl-w-175px sl-border-l-1px-transparent"></td>
            <td class="sl-w-100px sl-border-l-1px-transparent"></td>
            <td class="sl-w-60px sl-white-space-no-wrap"></td>
            <td class="sl-w-60px sl-border-l-1px-transparent">Scenario</td>
            <td class="sl-w-60px sl-border-l-1px-transparent"></td>
            <td class="sl-w-400px"></td>
          </tr>
          <tr class="sl-h-28px">
            <td class="sl-w-100px">Installed Cost</td>
            <td class="sl-w-175px">Cost Certainty</td>
            <td class="sl-w-100px">RUL Year</td>
            <td class="sl-w-100px">Electricity</td>
            <td class="sl-w-100px">{{ secondaryFuelType.display_name }}</td>
            <td class="sl-w-100px">Water</td>
            <td class="sl-w-100px">Electricity (kWh)</td>
            <td class="sl-w-100px">{{secondaryFuelType.display_name}} ({{ secondaryFuelType.unit_of_measure }})</td>
            <td class="sl-w-175px">Sub Category</td>
            <td class="sl-w-175px">System Type</td>
            <td class="sl-w-175px">Project Status</td>
            <td class="sl-w-100px">Year Installed</td>
            <td class="sl-w-60px sl-cursor-pointer" title="Select All/Clear All" @click='toggleScenario(1)'>1</td>
            <td class="sl-w-60px sl-cursor-pointer" title="Select All/Clear All" @click='toggleScenario(2)'>2</td>
            <td class="sl-w-60px sl-cursor-pointer" title="Select All/Clear All" @click='toggleScenario(3)'>3</td>
            <td class="sl-w-400px">Notes</td>
          </tr>
        </thead>
        <tbody id="ewem_table_main_body">
          <tr v-for="(ewem, index) in ewems"
              :class="{ 'sl-border-b-1px': isLastRow(ewem) }"
              class="sl-table__row sl-table__row--esg"
              :id="`main_body_${ewem.id}`">

            <!-- Installed Cost Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.installed_cost" @change="saveEwem(ewem, $event)"
                     column="installed_cost" type="text" class="sl-w-85px"/>
            </td>

            <!-- Cost Certainty Column -->
            <td class="sl-w-175px">
              <select v-model="ewem.cost_certainty" @change="saveEwem(ewem, $event)" column="cost_certainty"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in costCertaintyOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Remaining Useful Life Year Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.rul_year" @change="saveEwem(ewem, $event)"
                     column="rul_year" type="number" class="sl-w-85px sl-disable-number-input-up-down-arrows"/>
            </td>

            <!-- Annual Cost Savings Electricity Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_total_electricity" @change="saveEwem(ewem, $event)"
                     column="annual_cost_savings_total_electricity" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Cost Savings Gas Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_total_gas" @change="saveEwem(ewem, $event)"
                     column="annual_cost_savings_total_gas" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Cost Savings Water Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.annual_cost_savings_water" @change="saveEwem(ewem, $event)"
                     column="annual_cost_savings_water" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Site Energy Savings Electricity Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.annual_site_energy_savings_total_electricity" @change="saveEwem(ewem, $event)"
                     column="annual_site_energy_savings_total_electricity" type="text" class="sl-w-85px"/>
            </td>

            <!-- Annual Site Energy Savings Gas Column -->
            <td class="sl-w-100px">
              <input v-model="ewem.annual_site_energy_savings_total_gas" @change="saveEwem(ewem, $event)"
                     column="annual_site_energy_savings_total_gas" type="text" class="sl-w-85px"/>
            </td>

            <!-- Sub Category -->
            <td class="sl-w-175px">
              <select v-model="ewem.category" @change="saveEwem(ewem, $event)" column="category"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in categoryOptions">{{ option }}</option>
              </select>
            </td>

            <!-- System Type -->
            <td class="sl-w-175px">
              <select v-model="ewem.system_type" @change="saveEwem(ewem, $event)" column="system_type"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in systemTypeOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Status -->
            <td class="sl-w-175px">
              <select v-model="ewem.status" @change="saveEwem(ewem, $event)" column="status"
                      class="sl-w-140px sl-border-2px-medium-charcoal sl-pt-2px sl-pb-3px">
                <option v-for="option in statusOptions">{{ option }}</option>
              </select>
            </td>

            <!-- Year Installed -->
            <td class="sl-w-100px">
              <input v-model="ewem.year_installed" @change="saveEwem(ewem, $event)"
                    column="year_installed" type="number" class="sl-w-85px sl-disable-number-input-up-down-arrows"/>
            </td>

            <!-- Scenario 1 -->
            <td class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_1`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_1`"
                       type='checkbox'
                       column='scenario_1'
                       @change="saveEwem(ewem, $event)"
                       :checked="ewem.scenario_1"/>
                <span class='checkmark'></span>
              </label>
            </td>

            <!-- Scenario 2 -->
            <td class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_2`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_2`"
                        type='checkbox'
                        column='scenario_2'
                        @change="saveEwem(ewem, $event)"
                        :checked="ewem.scenario_2"/>
                <span class='checkmark'></span>
              </label>
            </td>
            <!-- Scenario 3 -->
            <td class="sl-w-60px">
              <label :for="`${ewem.id}_scenario_3`" class='sl-custom-checkbox sl-ml-5px sl-mb-20px'>
                <input :id="`${ewem.id}_scenario_3`"
                       type='checkbox'
                       column='scenario_3'
                       @change="saveEwem(ewem, $event)"
                       :checked="ewem.scenario_3"/>
                <span class='checkmark'></span>
              </label>
            </td>
            <!-- Notes -->
            <td class="sl-w-400px">
              <textarea v-model="ewem.notes"
                        @change="saveEwem(ewem, $event)" column="notes"
                        @dblclick="toggleExpand(ewem)"
                        @mouseenter="showExpandTitle($event)"
                        class="sl-w-400px sl-overflow-y-scroll sl-pl-10px"
                        :class="{ expandedTextCell: ewem.expand}">
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <!-- Bottom Buttons -->
  <div class="sl-flex-justify-content-center sl-mt-20px">
    <div class="sl-flex-justify-content-space-btwn sl-w-100per" style="max-width: 1940px;">
      <div class="sl-w-33per"></div>
      <div class="sl-w-33per sl-flex-justify-content-center">
        Click on cell to enter data. Table will auto save.
      </div>
      <div class="sl-w-33per sl-flex-justify-content-end">
        <button @click="deleteAllEwems" class="sl-simple-outline-btn-warning sl-w-100px">Delete All</button>
      </div>
    </div>
  </div>
  <div class='sl-mt-200px'></div>
</template>
<script>
import axios from 'axios';
import Sortable from 'sortablejs';
import SpinningModal from '/javascript/components/SpinningModal.vue';
import ExcelDropzone from '../../components/ExcelDropzone.vue';
import EwemImportModal from './EwemImportModal.vue';
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import plusIcon from '../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';
import infoIcon from '../../../../assets/images/icons/information_circle_icon.svg';
import handle from '../../../../assets/images/icons/handle.svg';


export default {
  components: {
    SpinningModal,
    ExcelDropzone,
    EwemImportModal,
    deleteIcon,
    plusIcon,
    infoIcon,
    handle,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    const baselineMetricId = urlParams.get('baseline_metric_id');
    return {
      ewems: [],
      baselineMetricId,
      costCertaintyOptions: [],
      categoryOptions: [],
      systemTypeOptions: [],
      statusOptions: [],
      baselineYear: null,
      maxDecarbYear: null,
      maxRowsAllowed: 30,
      showLoadingModal: false,
      alertMessage: '',
      dataReady: false,
      secondaryFuelType: {},
      showNotesContainer: false,
      rowsToAdd: 1,
      showImportModal: false,
      componentKey: 0,
    };
  },

  async created() {
    this.fetchEwems();
  },

  methods: {
    async fetchEwems() {
      await axios.get('/ewems/vue_index', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.ewems = response.data.ewems;
          this.costCertaintyOptions = response.data.costCertaintyOptions;
          this.categoryOptions = response.data.categoryOptions;
          this.systemTypeOptions = response.data.systemTypeOptions;
          this.statusOptions = response.data.statusOptions;
          this.baselineYear = response.data.baselineYear;
          this.maxDecarbYear = response.data.maxDecarbYear;
          this.secondaryFuelType = response.data.secondaryFuelType;
          this.setSortable();
          this.dataReady = true;
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },

    async saveEwem(ewem, event) {
      const column = event.target.getAttribute('column');
      const value = this.formatValue(ewem, event);

      if (this.validateInput(column, value)) {
        await axios.put('/ewems/vue_update', null, {
          params: {
            id: ewem.id,
            column,
            value,
          },
        })
          .then(() => {
            event.target.style.color = 'black';
            this.flashSaveNotice();
          })
          .catch((error) => {
            console.log(error);
            this.showErrorNotice();
          });
      } else {
        event.target.style.color = 'red';
      }
    },

    async createEwem() {
      if (this.checkNumberOfRows()) {
        await axios.post('/ewems/vue_create', null, {
          params: {
            baseline_metric_id: this.baselineMetricId,
            number_of_rows: this.rowsToAdd,
          },
        })
          .then((response) => {
            this.ewems = this.ewems.concat(response.data.ewems);
            this.ewems.sort((a, b) => a.index_number - b.index_number);
            // Force the component to re-render
            this.componentKey += 1;
            this.rowsToAdd = 1;
            setTimeout(() => { this.setSortable() }, 300);
          })
          .catch((error) => {
            console.log(error);
            this.showErrorNotice();
          });
      }
    },

    async deleteEwem(ewem) {
      if (this.ewemIsEmpty(ewem) || confirm(`Are you sure want to delete "${ewem.description}"?`)) {
        // Remove the ewem from the array
        this.ewems = this.ewems.filter((e) => e.id !== ewem.id);
        // Reorder the ewems based on the index_number        
        this.ewems.sort((a, b) => a.index_number - b.index_number);
        // Update the index_number field to fill in the gap
        this.ewems.forEach((e, index) => {
          e.index_number = index + 1;
        });
        // Force the component to re-render, this is needed to keep the elements in the correct order in the html table
        this.componentKey += 1;
        setTimeout(() => { this.setSortable() }, 300);
        // Delete the ewem from the database
        await axios.delete('/ewems/vue_destroy', {
          params: {
            id: ewem.id,
            baseline_metric_id: this.baselineMetricId,
          },
        })
          .catch(() => {
            this.showErrorNotice();
          });
      }
    },

    // Delete all ewems on a project
    async deleteAllEwems() {
      if (confirm('Are you sure want to delete all Ewems for this project?')) {
        this.showLoadingModal = true;
        await axios.delete('/ewems/vue_destroy_all', {
          params: {
            baseline_metric_id: this.baselineMetricId,
          },
        })
          .then(() => {
            this.ewems = [];
            setTimeout(() => {
              this.showLoadingModal = false;
            }, 300);
          })
          .catch((error) => {
            console.log(error);
            this.showErrorNotice();
            this.showLoadingModal = false;
          });
      }
    },

    // Check the number of rows before adding a new row
    checkNumberOfRows() {
      if ((this.ewems.length + this.rowsToAdd) < this.maxRowsAllowed) {
        return true;
      }
      alert('The maximum number of rows to add is 30');
      return false;
    },

    // Validate values before sending axios call
    validateInput(column, value) {
      // The year should be between the baseline year and the max decarb year, allow zero for rul_year
      if ((column ==  'year_installed' && (value < this.baselineYear || value > this.maxDecarbYear)) ||
          (column == 'rul_year' && value != 0 && (value < this.baselineYear || value > this.maxDecarbYear))) {  
        this.alertMessage = `Error: The year must range from ${this.baselineYear} to ${this.maxDecarbYear}`;
        return false;
      }
      return true;
    },

    // Format number as currency or number with delimeter
    formatValue(ewem, event) {
      const column = event.target.getAttribute('column');
      let { value } = event.target;

      if (['installed_cost', 'annual_cost_savings_total_electricity',
        'annual_cost_savings_total_gas', 'annual_cost_savings_water'].includes(column)) {
        value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
        ewem[column] = this.numberToCurrency(value);
      } else if (['annual_site_energy_savings_total_electricity',
        'annual_site_energy_savings_total_gas'].includes(column)) {
        value = value.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
        ewem[column] = this.numberWithDelimiter(value);
      } else if (['scenario_1', 'scenario_2', 'scenario_3'].includes(column)) {
        value = event.target.checked;
        ewem[column] = value;
      }
      return value;
    },

    // Return true if the ewem is the last row on the table
    isLastRow(ewem) {
      return ewem.index_number === this.ewems.length;
    },

    // Return true if the ewem does not have a description
    ewemIsEmpty(ewem) {
      return (ewem.description === null || ewem.description === '');
    },

    numberToCurrency(number) {
      return `$${Intl.NumberFormat().format(Math.round(number))}`;
    },

    numberWithDelimiter(number) {
      return Intl.NumberFormat().format(Math.round(number));
    },

    flashSaveNotice() {
      this.alertMessage = 'Table Saved';
      setTimeout(() => { this.alertMessage = ''; }, 1000);
    },

    showErrorNotice() {
      this.alertMessage = 'An error has occurred and the data entered has not been saved';
    },

    uploadComplete() {
      this.showImportModal = false;
      this.fetchEwems();
      this.alertMessage = 'Import Complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 2000);
    },

    toggleExpand(ewem) {
      ewem.expand = !ewem.expand;
    },
    
    async toggleScenario(scenario) {
      const allEmpty = this.ewems.every((ewem) => !ewem[`scenario_${scenario}`]);
      const boolean = allEmpty ? true : false;
      this.ewems.forEach((ewem) => {
        ewem[`scenario_${scenario}`] = boolean;
      });
      await axios.put('/ewems/update_scenario_for_all_ewems', null, {
        params: {
          baseline_metric_id: this.baselineMetricId,
          scenario,
          boolean,
        },
      })
        .then(() => {
          this.flashSaveNotice();
        })
        .catch(() => {
          this.showErrorNotice();
        });
    },

    setSortable() {
      const el = document.getElementById('pl_table_body');
      Sortable.create(el, {
        handle: '.sortable-handle',
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: () => {
          this.updateSortOrder();
        },
      });
    },

    // Loop through elements in the table and update the index_number field
    async updateSortOrder() {
      const el = document.getElementById('pl_table_body');
      let index = 1;
      let sortedIds = [];
      for (let row of el.children) { // eslint-disable-line no-restricted-syntax, prefer-const
        const htmlRow = this.ewems.find((r) => r.id == row.id); // eslint-disable-line eqeqeq
        if (htmlRow) {
          htmlRow.index_number = index;
          index++;
          sortedIds.push(htmlRow.id);
        }
      }
      
      // Update the main table and delete column, these are seperate html tables
      const mainTableBody = document.getElementById('ewem_table_main_body');
      let sortedBodyRows = [];
      for (let id of sortedIds) {
        const htmlRow = document.getElementById(`main_body_${id}`);
        if (htmlRow) {
          sortedBodyRows.push(htmlRow);
        }
      }
      for (let row of sortedBodyRows) {
        mainTableBody.appendChild(row);
      }
      let sortStatus = [];
      for (let ewem of this.ewems) {
        sortStatus.push({
          id: ewem.description,
          index_number: ewem.index_number,
        });
      }

      // Update the index_number field in the database
      await axios.put('/ewems/update_sort_order', null, {
        params: {
          baseline_metric_id: this.baselineMetricId,
          sorted_ids: sortedIds,
        },
      })
        .then(() => {
          this.flashSaveNotice();
        })
        .catch(() => {
          this.showErrorNotice();
        });

    },

    // Show this message next to the pointer after a delay
    showExpandTitle(event) {
      setTimeout(() => {
        event.target.title = 'Double click to expand';
      }, 1000);
    },
  },
};
</script>
<style scoped>
  thead td {
    height: 28px;
  }

  thead tr:first-child {
    border-bottom: 0 !important;
  }

  textarea {
    height: 40px;
    transition: height 0.3s;
  }

  thead tr:first-child td:nth-child(7) {
   justify-content: flex-end;
  }

  thead tr:first-child td:nth-child(8) {
   justify-content: flex-start;
  }

  #delete-column-header tr:first-child td {
    border-left: 1px solid #9F9EA4;
  }

  .ewem-notes-container {
    background-color: white;
    border: 1px solid #9F9EA4;
    height: 200px;
    width: 360px;
    margin: 10px 0;
    padding: 5px 10px;
  }

  .expandedTextCell {
    height: 200px;
    transition: height 0.3s;
  }

  .sortable-ghost {
    opacity: 0.4;
  }
</style>
