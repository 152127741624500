<template>
  <div class="sl-mb-14px sl-sub-header-blue-10">
    Step 1 of 4 | Enter the location, type, & loan details
  </div>

  <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px sl-mb-10px">
    <div class="sl-paragraph-med-bold">Site Location</div>
  </div>

  <!-- Line 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 70%" class="sl-mr-10px">
      <div class="sl-para-small">Property Name</div>
      <input type="text"
            name="property_name"
            class="sl-input-type-1"
            maxlength="200"
            v-model="this.passed_in_order_form_data.property_location.name" />
    </div>

    <div style="width: 30%">
      <div class="sl-para-small">Zip *</div>
      <input type="text"
              name="zip"
              class="sl-input-type-1 required-field"
              maxlength="10"
              oninput="this.value = this.value.replace(/[^0-9-]/g, '')"
              @click="resetRequiredFieldStyle"
              v-model="this.passed_in_order_form_data.property_location.zip" />
    </div>
  </div>

  <!-- Line 2 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Address</div>
      <input
        type="text"
        name="address"
        class="sl-input-type-1"
        maxlength="50"
        v-model="this.passed_in_order_form_data.property_location.address"
      />
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">City</div>
      <input type="text"
            name="city"
            class="sl-input-type-1" maxlength="50" v-model="this.passed_in_order_form_data.property_location.city" />
    </div>
  </div>

  <!-- Line 3 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">State/Province/Region</div>
      <select class="sl-input-type-1"
            name="state"
            v-model="this.passed_in_order_form_data.property_location.state">
        <option hidden disabled value="">Choose a Selection</option>
        <option value=""></option>
        <option v-for="value in this.dropdown_selections.states" :key="value" :value="value">{{ value }}</option>
      </select>
    </div>

    <div style="width: 40%" class="sl-mr-10px">
      <div class="sl-para-small">Country</div>
      <select class="sl-input-type-1"
            name="country"
            v-model="this.passed_in_order_form_data.property_location.country">
        <option hidden disabled value="">Choose a Selection</option>
        <option value=""></option>
        <option v-for="value in this.dropdown_selections.countries" :key="value" :value="value">{{ value }}</option>
      </select>
    </div>

    <div style="width: 20%">
      <div class="sl-para-small">APN/Parcel/Lot #</div>
      <input type="text"
            name="apn"
            class="sl-input-type-1" maxlength="100" v-model="this.passed_in_order_form_data.property_location.apn" />
    </div>
  </div>

  <!-- Line 4 -->
  <div class="sl-flex-justify-content-end sl-mtb-14px ">
    <div class="sl-flex-align-items-flex-end">
      <button @click="geocode_and_set_location_data" :disabled="map_btn_disabled" class="sl-simple-outline-btn" style="min-width: 20%;">Show on Map</button>
    </div>
  </div>

  <!-- ------------------------------------------------------------------------------------------------------------------- -->
  <!-- Property Type -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
    <div class="sl-paragraph-med-bold">Property Type</div>
  </div>

  <div class="sl-mb-14px sl-font-size-10px">A primary property type must be selected to select a secondary property type.</div>

  <div class="sl-flex-only sl-mb-14px">
    <!-- TODO: (Paul) -->
    <!-- <div class="sl-mr-10px">
      <button>?</button>
    </div> -->
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Primary Property Type *</div>
      <select class="required-field sl-input-type-1"
            name="primary_property_type"
            v-model="this.passed_in_order_form_data.property_type_data.primary_property_type"
            @click="resetRequiredFieldStyle"
            @change="reset_second_property_type">
        <option hidden disabled value="">Choose a Selection</option>
        <option v-for="(value, key) in this.dropdown_selections.primary_property_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Secondary Property Type</div>
      <select class="sl-input-type-1"
              name="secondary_property_type"
              v-model="this.passed_in_order_form_data.property_type_data.secondary_property_type"
              :disabled="this.passed_in_order_form_data.property_type_data.primary_property_type === '' ? true : false">
        <option value=""></option>
        <option v-for="(value, key) in secondary_property_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>
  </div>

  <!-- ------------------------------------------------------------------------------------------------------------------- -->
  <!-- Loan Details 1 -->
  <div class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 50%" class="sl-mr-10px">
      <div class="sl-para-small">Purpose of Order *</div>
      <select class="sl-input-type-1 required-field"
              name="purpose_of_order"
              v-model="this.passed_in_order_form_data.loan_data.purpose_of_order"
              @click="resetRequiredFieldStyle"
              @change="reset_agency_type_and_loan_type">
        <option disabled value="">Choose a Selection</option>
        <option v-for="(value, key) in this.dropdown_selections.purpose_of_order_selections" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>

    <div style="width: 50%">
      <div class="sl-para-small">Client Reference/Loan #</div>
      <input type="text"
            name="client_reference_loan_number"
            oninput="this.value = this.value.replace(/[^0-9-]/g, '')"
            maxlength="25" class="sl-input-type-1" v-model="this.passed_in_order_form_data.loan_data.client_reference_loan_number" />
    </div>
  </div>

  <!-- Loan Details 2 -->
  <!-- Shown if purpose of order is type_2 "financing agency" -->
  <div v-if="agency_client" class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 52%;" id="sl-agency-type-container">
      <div class="sl-para-small">Agency Type *</div>
      <span v-for="(value, key) in this.dropdown_selections.agency_types" :key="key" style="display: inline-block; margin: 5px;">
        <label :for="'radio_agency_' + key" class="sl-custom-radio sl-mr-5px" style="display: inline;">
          {{ value }}
          <input type="radio"
                :id="'radio_agency_' + key"
                :name="'agency_type'"
                :value="value"
                v-model="this.passed_in_order_form_data.loan_data.agency_type"
                class="sl-agency-type"
                @change="reset_loan_type">
          <span class="checkmark"></span>
        </label>
      </span>
    </div>

    <div class="sl-pr-5px" style="width: 48%;">
      <div class="sl-para-small">Loan Type * <span>(An Agency must be selected first)</span></div>
      <select class="required-field sl-input-type-1"
              name="agency_loan_type"
              v-model="this.passed_in_order_form_data.loan_data.loan_type"
              @click="resetRequiredFieldStyle"
              :disabled="this.passed_in_order_form_data.loan_data.agency_type === '' ? true : false">
        <option disabled value=""></option>
        <option v-for="(value, key) in agency_loan_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>
  </div>
  <!-- Else show the NON-required loan type field -->
  <div v-else class="sl-flex-justify-content-space-btwn sl-mb-14px">
    <div style="width: 100%;">
      <div class="sl-para-small">Loan Type</div>
      <select class="sl-input-type-1"
              name="non_agency_loan_type"
              v-model="this.passed_in_order_form_data.loan_data.loan_type">
        <option value=""></option>
        <option v-for="(value, key) in this.dropdown_selections.non_agency_loan_types" :key="key" :value="value">{{ value }}</option>
      </select>
    </div>
  </div>

  <!-- Loan Details 3 -->
  <!-- Show if "Other" is selected on purpose of order -->
  <div class="sl-pr-5px sl-mb-14px" v-if="this.passed_in_order_form_data.loan_data.purpose_of_order === 'Other'">
    <div class="sl-para-small">Describe the purpose of order</div>
    <input type="text"
          name="describe_purpose_of_order"
          maxlength="100" class="sl-input-type-1" v-model="this.passed_in_order_form_data.loan_data.describe_purpose_of_order" />
  </div>

  <hr/>
  <div class="sl-mtb-10px sl-flex-justify-content-end">
    <span v-if="required_fields_missing" class="sl-flex-align-items-center sl-flex-justify-content-end sl-plr-10px sl-required-text"
                                         style="flex-grow: 1;">
      * Required fields are incomplete
    </span>
    <button @click="next_page" class="sl-generic-blue-background-btn sl-custom-btn-padding-1">Continue</button>
  </div>
</template>

<script>
/* eslint-disable no-new */
/* global google */
import { Loader as MapLoader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import { cloneDeep as _cloneDeep } from 'lodash';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
    google_map_data: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
  },

  data() {
    return {
      required_fields_missing: false,
      last_geocoded_data: {}, // used to check if the any data has been updated
    };
  },

  computed: {
    map_btn_disabled() {
      if (
        this.passed_in_order_form_data.property_location.address === ''
          && this.passed_in_order_form_data.property_location.city === ''
          && this.passed_in_order_form_data.property_location.state === ''
          && this.passed_in_order_form_data.property_location.zip === ''
          && this.passed_in_order_form_data.property_location.country === ''
      ) {
        return true;
      }
      return false;
    },

    // Returns the secondary property types dropdown list based on the primary property type selection
    secondary_property_types() {
      if (this.passed_in_order_form_data.property_type_data.primary_property_type === '') {
        return [];
      }

      return this.dropdown_selections.secondary_property_types[this.passed_in_order_form_data.property_type_data.primary_property_type];
    },

    agency_client() {
      return this.passed_in_order_form_data.loan_data.purpose_of_order === 'Financing Agency';
    },

    // Returns the loan type dropdown list based on the agency type selection
    agency_loan_types() {
      if (this.passed_in_order_form_data.loan_data.agency_type === '') {
        return [];
      }

      return this.dropdown_selections.agency_loan_types[this.passed_in_order_form_data.loan_data.agency_type];
    },
  },

  mounted() {
    // make a copy of the current property_location data
    this.last_geocoded_data = _cloneDeep(this.passed_in_order_form_data.property_location);

    if (this.google_map_data.api_key !== '') {
      this.load_map(false);
      return;
    }

    // sets the google_map_api_key and loads the default map, only if the api key is not set
    axios.get('/project_orders/load_initial_order_form_data')
      .then((response) => {
        this.google_map_data.api_key = response.data.google_map_api_key;
        this.load_map(false);
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    next_page() {
      if (this.check_required_fields()) {
        this.required_fields_missing = true;
        return;
      }

      this.get_and_set_radon_zone();
      this.$emit('next_page');
    },

    // check required input fields
    check_required_fields() {
      let required_fields_missing = false;

      const required_fields = document.getElementsByClassName('required-field');
      for (let i = 0; i < required_fields.length; i++) {
        if (required_fields[i].value === '') {
          required_fields_missing = true;
          required_fields[i].classList.add('sl-input-error');
          required_fields[i].parentNode.classList.add('sl-input-title-error');
        } else {
          required_fields[i].classList.remove('sl-input-error');
          required_fields[i].parentNode.classList.remove('sl-input-title-error');
        }
      }

      const agency_type_container = document.getElementById('sl-agency-type-container');
      const agency_type_radios = document.getElementsByClassName('sl-agency-type');

      // special case for agency type radios, check if 1 is selected
      if (agency_type_container) {
        let agency_type_selected = false;

        for (let i = 0; i < agency_type_radios.length; i++) {
          if (agency_type_radios[i].checked) {
            agency_type_selected = true;
            break;
          }
        }

        // if no agency type is selected, add error class
        if (!agency_type_selected) {
          required_fields_missing = true;
          agency_type_container.classList.add('sl-input-error');
        } else {
          agency_type_container.classList.remove('sl-input-error');
        }
      }

      return required_fields_missing;
    },

    // resets the required field styles on the input and title
    resetRequiredFieldStyle(e) {
      e.target.classList.remove('sl-input-error');
      e.target.parentNode.classList.remove('sl-input-title-error');
    },

    // API call to get Radon zone
    get_and_set_radon_zone() {
      axios.get('/project_orders/return_radon_zone', {
        params: {
          property_location: this.passed_in_order_form_data.property_location,
        },
      })
        .then((response) => {
          this.passed_in_order_form_data.property_location.radon_zone = response.data.radon_zone;
          this.passed_in_order_form_data.property_location.county = response.data.county;
        })
        .catch((error) => {
          alert(error);
        });
    },

    geocode_and_set_location_data() {
      // compare the current geocode data with the last geocode data
      if (
        this.passed_in_order_form_data.property_location.lat !== ''
          && this.passed_in_order_form_data.property_location.lng !== ''
          && this.passed_in_order_form_data.property_location.address === this.last_geocoded_data.address
          && this.passed_in_order_form_data.property_location.city === this.last_geocoded_data.city
          && this.passed_in_order_form_data.property_location.state === this.last_geocoded_data.state
          && this.passed_in_order_form_data.property_location.zip === this.last_geocoded_data.zip
          && this.passed_in_order_form_data.property_location.country === this.last_geocoded_data.country
      ) {
        // if they are the same, no api call, just load the map
        this.load_map(true);
        return;
      }

      // send api call to geocode the address
      axios.get('/project_orders/geocode_address', {
        params: {
          address: this.passed_in_order_form_data.property_location.address,
          city: this.passed_in_order_form_data.property_location.city,
          state: this.passed_in_order_form_data.property_location.state,
          zip: this.passed_in_order_form_data.property_location.zip,
          country: this.passed_in_order_form_data.property_location.country,
        },
      })
        .then((response) => {
          if (response.data.geocode_success) {
            // update lat and long on the order form data AND map data
            this.passed_in_order_form_data.property_location.lat = response.data.lat;
            this.passed_in_order_form_data.property_location.lng = response.data.lng;
            this.google_map_data.lat = response.data.lat;
            this.google_map_data.lng = response.data.lng;
            this.google_map_data.zoom = 15;

            // then load the map
            this.load_map(true);
          } else {
            alert('No location found.');
          }

          // update the last geocoded data
          this.last_geocoded_data = {
            address: this.passed_in_order_form_data.property_location.address,
            city: this.passed_in_order_form_data.property_location.city,
            state: this.passed_in_order_form_data.property_location.state,
            zip: this.passed_in_order_form_data.property_location.zip,
            country: this.passed_in_order_form_data.property_location.country,
          };
        }).catch((error) => { alert(error); });
    },

    // loads the map based on the google_map_data
    load_map(add_marker) {
      // check if map container exists
      const map_container = document.getElementById('map-container');
      if (!map_container) {
        return;
      }

      const loader = new MapLoader({
        apiKey: this.google_map_data.api_key,
        version: 'weekly',
      });

      loader.load().then(async () => {
        const latLong = { lat: this.google_map_data.lat, lng: this.google_map_data.lng };
        const map = new google.maps.Map(map_container, {
          center: latLong,
          zoom: this.google_map_data.zoom,
        });

        if (add_marker) {
          new google.maps.Marker({
            position: latLong,
            map,
          });
        }
      });
    },

    reset_second_property_type() {
      this.passed_in_order_form_data.property_type_data.secondary_property_type = '';
    },

    reset_agency_type_and_loan_type() {
      this.passed_in_order_form_data.loan_data.agency_type = '';
      this.passed_in_order_form_data.loan_data.loan_type = '';
    },

    reset_loan_type() {
      this.passed_in_order_form_data.loan_data.loan_type = '';
    },
  },
};
</script>
