<template>
  <SpinningModal v-if='showLoadingModal'/>
  <CustomPathwayModal
    v-if='showPercentageModal'
    @close-modal='showPercentageModal = false;'
    @create='create'
    :years='years'
  />
  <div v-if='dataReady' class='sl-mt-10px sl-mb-40px sl-flex-align-items-center'>
    <h1 class='nd_photos_h1_style'>Custom Pathways</h1>
  </div>
  <div v-if='dataReady' class='sl-flex-justify-content-center'>
    <div>
      <p>
        Upload your CSV or input your data directly below.
      </p>
      <p>
        CSVs must have been created using the Partner template file.
      </p>
      <p>
        Click <a href='/decarb_custom_pathways/download_custom_pathways_template'>here</a> to download the template.
      </p>
    </div>
  </div>
  <ExcelDropzone v-if='dataReady'
    @upload-complete='uploadComplete'
    :formUrl='`/esg_excel_imports/import_decarb_custom_pathways/?baseline_metric_id=${baselineMetricId}`'
    :title="'Upload your Custom Pathways'"
  />
  <br>
  <br>
  <div class='sl-w-100per sl-text-align-center sl-h-15px' style='color: red;'>{{ alertMessage }}</div>
  <div v-if='dataReady'>
    <div class='sl-flex-only sl-mt-5px'>
      <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
        <thead>
          <tr class='sl-table__row sl-table__row--esg'>
            <td class='sl-w-200px'>Name</td>
          </tr>
        </thead>
        <tbody>
          <div v-for='pathwayType in customPathways'>
            <tr class='sl-h-28px sl-esg-table-sub-header-row sl-flex-align-items-center'>
              <td class='sl-w-200px sl-flex-justify-content-start sl-h-28px'>
                <div class='sl-ml-10px'>{{ `${pathwayType.name} (${pathwayType.units})` }}</div>
              </td>
            </tr>
            <tr v-if='!pathwayType.pathways.length' class='sl-table__row sl-table__row--esg sl-h-42px'
                :class="{ 'sl-border-b-1px': isLastRow(index, pathwayType) }">
              <td class='sl-w-200px sl-flex-justify-content-start'>
                <div class='sl-ml-10px' style='color: #C5C5C8'>Create a new pathway</div>
              </td>
            </tr>
            <tr v-else v-for='(pathway, index) in pathwayType.pathways' class='sl-table__row sl-table__row--esg sl-h-42px'
                :class="{ 'sl-border-b-1px': isLastRow(index, pathwayType) }">
              <td class='sl-w-200px sl-flex-justify-content-start'>
                <input v-model='pathway.name' placeholder='Add name here...' type='text' class='sl-w-200px sl-pl-10px' style='text-align: left;'/>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
      <div class='sl-esg-overflow-container'>
        <table class='sl-esg-decarbonization-table'>
          <thead>
            <tr class='sl-table__row sl-table__row--esg'>
              <td v-for='year in years' class='sl-w-85px sl-font-size-12px'>{{ year }}</td>
            </tr>
          </thead>
          <tbody>
            <div v-for='pathwayType in customPathways'>
              <tr class='sl-table__row sl-esg-table-sub-header-row sl-h-28px'></tr>
              <tr v-if='!pathwayType.pathways.length' class='sl-table__row sl-table__row--esg sl-h-42px'>
                <td v-for='year in years' class='sl-w-85px' style='color: #C5C5C8'>&#8212</td>
              </tr>
              <tr v-else v-for='pathway in pathwayType.pathways' class='sl-table__row sl-table__row--esg sl-h-42px'>
                <td v-for='year in years' class='sl-w-85px'>
                  <input v-model='pathway.values[year]' @change='formatValue(pathway, year, $event)'
                        :id='`${pathway.id}_${year}`' type='text' placeholder='&#8212' class='sl-w-60px'/>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
      <table class='sl-esg-decarbonization-table sl-border-l-1px-medium-charcoal'>
        <thead>
          <tr>
            <td>
              <div class='sl-w-45px'></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <div v-for='pathwayType in customPathways'>
            <tr class='sl-table__row sl-esg-table-sub-header-row sl-h-28px'></tr>
            <tr v-if='!pathwayType.pathways.length' class='sl-table__row sl-table__row--esg'
                :class="{ 'sl-border-b-1px': isLastRow(index, pathwayType) }">
              <td class='sl-h-42px'></td>
            </tr>
            <tr v-else v-for='(pathway, index) in pathwayType.pathways' class='sl-table__row sl-table__row--esg'
                :class="{ 'sl-border-b-1px': isLastRow(index, pathwayType) }">
              <td>
                <div class='sl-w-45px sl-h-42px sl-flex-justify-align-center'>
                  <deleteIcon @click='deletePathway(pathway, index)' transform='scale(0.7)' class='figma-icon-delete links-center'/>
                </div>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
    <div class='sl-mt-20px sl-flex-justify-content-space-btwn sl-w-100per'>
      <div class='sl-flex-align-items-center'>
        <plusIcon @click='showPercentageModal=true' class='figma-icon-blue links-center sl-mr-20px'/>
        <button @click='save' class='sl-simple-outline-btn sl-w-100px'>Save</button>
      </div>
      <div class='sl-flex-align-items-center'>Click on cell to enter data</div>
      <div>
        <button @click='deleteAll' class='sl-simple-outline-btn-warning sl-w-100px'>Delete All</button>
      </div>
    </div>
  </div>
  <div class='sl-mt-200px'></div>
</template>
<script>
import axios from 'axios';
import SpinningModal from '../../components/SpinningModal.vue';
import ExcelDropzone from '../../components/ExcelDropzone.vue';
import plusIcon from '../../../../assets/images/icons/Icon-Add_Charcoal-Dark-100_24.svg';
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import CustomPathwayModal from './CustomPathwayModal.vue';

export default {
  components: {
    SpinningModal,
    ExcelDropzone,
    CustomPathwayModal,
    plusIcon,
    deleteIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      baselineMetricId: urlParams.get('baseline_metric_id'),
      customPathways: [],
      years: [],
      alertMessage: '',
      showLoadingModal: false,
      showPercentageModal: false,
      dataReady: false,
    };
  },

  created() {
    this.fetchCustomPathways();
  },

  methods: {
    async fetchCustomPathways() {
      this.showLoadingModal = true;
      await axios.get('/decarb_custom_pathways/vue_index', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          this.years = response.data.years;
          this.dataReady = true;
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        })
        .catch(() => {
          this.alertMessage = 'Error fetching data';
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        });
    },

    async save() {
      this.showLoadingModal = true;
      await axios.put('/decarb_custom_pathways/vue_update', {
        baseline_metric_id: this.baselineMetricId,
        pathways: this.customPathways,
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Table Saved';
          }, 500);
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Error saving table';
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        });
    },

    async deletePathway(pathway, index) {
      let message = 'Are you sure you want to delete this pathway?';
      if (pathway.name) {
        message = `Are you sure you want to delete the ${pathway.name} pathway?`;
      }
      if (!window.confirm(message)) {
        return;
      }
      this.showLoadingModal = true;
      await axios.post('/decarb_custom_pathways/vue_destroy', {
        id: pathway.id,
      })
        .then(() => {
          this.customPathways.find((pathwayType) => pathwayType.name === pathway.type).pathways.splice(index, 1);
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathway Deleted';
          }, 500);
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Error deleting pathway';
          setTimeout(() => {
            this.showLoadingModal = false;
          }, 500);
        });
    },

    async deleteAll() {
      if (!window.confirm('Are you sure you want to delete all custom pathways for this project?')) {
        return;
      }
      this.showLoadingModal = true;
      await axios.post('/decarb_custom_pathways/vue_destroy_all', {
        baseline_metric_id: this.baselineMetricId,
      })
        .then((response) => {
          this.customPathways = response.data.custom_pathways;
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathways Deleted';
          }, 500);
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Error clearing table';
          }, 500);
        });
    },

    async create(pathwayType, customType, pathwayName, percentages, goalYears) {
      const pathwayCount = this.customPathways.find((pathway_type) => pathway_type.name === pathwayType).pathways.length;
      if (pathwayCount > 3) {
        alert('You can only have 4 pathways per pathway type.');
        return;
      }
      this.showLoadingModal = true;
      this.showPercentageModal = false;
      axios.post('/decarb_custom_pathways/vue_create', {
        baseline_metric_id: this.baselineMetricId,
        pathway_type: pathwayType,
        custom_type: customType,
        pathway_name: pathwayName,
        percent_reductions: percentages,
        goal_years: goalYears,
      })
        .then((response) => {
          this.customPathways.find((pathway_type) => pathway_type.name === pathwayType).pathways.push(response.data.pathway);
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Pathway Created';
          }, 500);
          setTimeout(() => { this.alertMessage = ''; }, 2000);
        })
        .catch(() => {
          this.showLoadingModal = false;
          this.alertMessage = 'Error creating pathway';
        });
    },

    // This function formats the input values to replace non numerical characters and add thousandth commas
    formatValue(pathway, year) {
      let value = pathway.values[year];
      value = value.replace(/[^\d.-]/g, '');
      value = Intl.NumberFormat().format(value);
      pathway.values[year] = value; // eslint-disable-line no-param-reassign
    },

    uploadComplete() {
      this.fetchCustomPathways();
      this.alertMessage = 'Import Complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 2000);
    },

    isLastRow(index, pathwayType) {
      return (index === pathwayType.pathways.length - 1 && pathwayType.name === 'Energy Use Intensity')
             || (pathwayType.name === 'Energy Use Intensity' && pathwayType.pathways.length === 0);
    },
  },
};
</script>
