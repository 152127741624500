<template>
  <br>
  <div v-if='dataReady && !primaryPathwaySelected && !secondaryPathwaySelected'
       class='sl-border-b-2px sl-font-size-14px sl-pt-10px sl-pb-20px sl-text-align-center'>
    No Carbon Pathways Selected
  </div>
  <div v-if='dataReady'>
    <div v-if='primaryPathwaySelected'>
      <div class='sl-mt-20px sl-mb-5px sl-flex-justify-content-space-btwn'>
        <div class='sl-w-33per'>
          <span class="sl-font-size-14px">{{ primaryPathwayName }}</span>
        </div>
        <div class='sl-w-33per sl-text-align-center' style='color: red'>{{ alertMessage }}</div>
        <div class='sl-flex-justify-content-end sl-w-33per' id='carbon_offset_type_selection'>
          <label for='primary_total_carbon_offset' class='sl-custom-radio sl-mr-20px'>
            Total Cost
            <input @click="primaryOffsetType='total'"
                  id='primary_total_carbon_offset'
                  name='primary_offset_type'
                  :checked="primaryOffsetType=='total'"
                  type='radio'>
            <span class='checkmark'></span>
          </label>
          <label for='primary_annual_carbon_offset' class='sl-custom-radio sl-mr-20px'>
            Annual Cost
            <input @click="primaryOffsetType='annual'"
                  id='primary_annual_carbon_offset'
                  name='primary_offset_type'
                  :checked="primaryOffsetType=='annual'"
                  type='radio'>
            <span class='checkmark'></span>
          </label>
        </div>
      </div>
      <div>
        <div v-if="primaryOffsetType=='total'" class='sl-flex-only'>
          <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
            <thead>
              <tr class='sl-table__row sl-table__row--esg'>
                <td class='sl-w-130px sl-flex-justify-content-end'></td>
              </tr>
            </thead>
            <tbody>
              <tr v-if='!primaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>$/tCO2e</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>Cost ($)</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
                <td class='sl-w-130px'>tCO2e</td>
              </tr>
            </tbody>
          </table>
          <div class='sl-esg-overflow-container'>
            <table class='sl-esg-decarbonization-table sl-w-100per' id='sl-annual-carbon-offsets-table'>
              <thead>
                <tr class='sl-table__row sl-table__row--esg'>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                <tr v-if='!primaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td>
                    <div>
                      <input @change="updateCarbonOffset(primaryTotalOffset, true)"
                            v-model='primaryTotalOffset.price_per_metric_ton'
                            placeholder="$0.00"
                            type='text'
                            class='sl-w-100per'/>
                    </div>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-html='primaryTotalOffset.cost'></td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td>{{ primaryTotalOffset.emission_offset }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else-if="primaryOffsetType=='annual'" class='sl-flex-only'>
          <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
            <thead>
              <tr class='sl-table__row sl-table__row--esg'>
                <td class='sl-w-130px sl-flex-justify-content-end'>
                  <span class='sl-w-100per sl-h-45px'></span>
                </td>
                <td class='sl-w-85px sl-flex-justify-content-end'>
                  <span class='sl-w-100per sl-flex-justify-align-center sl-h-45px'>Total</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-if='!primaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>$/tCO2e</td>
                <td class='sl-w-85px'>{{ primaryOffsetTotals.price_per_metric_ton }}</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>Cost ($)</td>
                <td class='sl-w-85px'>{{ primaryOffsetTotals.cost }}</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
                <td class='sl-w-130px'>tCO2e</td>
                <td class='sl-w-85px'>{{ primaryOffsetTotals.emission_offset }}</td>
              </tr>
            </tbody>
          </table>
          <div class='sl-esg-overflow-container'>
            <table class='sl-esg-decarbonization-table' id='sl-annual-carbon-offsets-table'>
              <thead>
                <tr class='sl-table__row sl-table__row--esg'>
                  <td v-for='year in years' class='sl-w-7per'>{{ year }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-if='!primaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='(carbonOffset, index) in primaryCarbonOffsets'>
                    <div>
                      <input @change="updateCarbonOffset(carbonOffset, true, index)"
                            v-model='carbonOffset.price_per_metric_ton'
                            placeholder="$0.00"
                            type='text'
                            class='sl-w-100per'/>
                    </div>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='carbonOffset in primaryCarbonOffsets' class='sl-w-7per'>
                    <span v-html='carbonOffset.cost'></span>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='carbonOffset in primaryCarbonOffsets' class='sl-w-7per' v-html='carbonOffset.emission_offset'></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div v-if='secondaryPathwaySelected'>
      <div class='sl-mt-20px sl-mb-5px sl-flex-justify-content-space-btwn'>
        <span class='sl-font-size-14px'>{{ secondaryPathwayName }}</span>
        <div class='sl-flex-justify-content-end sl-w-33per' id='carbon_offset_type_selection'>
          <label for='secondary_total_carbon_offset' class='sl-custom-radio sl-mr-30px'>
            Total Cost
            <input @click="secondaryOffsetType='total'"
                  id='secondary_total_carbon_offset'
                  name='secondary_offset_type'
                  :checked="secondaryOffsetType=='total'"
                  type='radio'>
            <span class='checkmark'></span>
          </label>
          <label for='secondary_annual_carbon_offset' class='sl-custom-radio sl-mr-10px'>
            Annual Cost
            <input @click="secondaryOffsetType='annual'"
                  id='secondary_annual_carbon_offset'
                  name='secondary_offset_type'
                  :checked="secondaryOffsetType=='annual'"
                  type='radio'>
            <span class='checkmark'></span>
          </label>
        </div>
      </div>
      <div>
        <div v-if="secondaryOffsetType=='total'" class='sl-flex-only'>
          <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
            <thead>
              <tr class='sl-table__row sl-table__row--esg'>
                <td class='sl-w-130px sl-flex-justify-content-end'></td>
              </tr>
            </thead>
            <tbody>
              <tr v-if='!secondaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>$/tCO2e</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>Cost ($)</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
                <td class='sl-w-130px'>tCO2e</td>
              </tr>
            </tbody>
          </table>
          <div class='sl-esg-overflow-container'>
            <table class='sl-esg-decarbonization-table sl-w-100per' id='sl-annual-carbon-offsets-table'>
              <thead>
                <tr class='sl-table__row sl-table__row--esg'>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                <tr v-if='!secondaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td>
                    <div>
                      <input @change="updateCarbonOffset(secondaryTotalOffset, false)"
                            v-model='secondaryTotalOffset.price_per_metric_ton'
                            placeholder="$0.00"
                            type='text'
                            class='sl-w-100per'/>
                    </div>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-html='secondaryTotalOffset.cost'></td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td>{{ secondaryTotalOffset.emission_offset }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else-if="secondaryOffsetType=='annual'" class='sl-flex-only'>
          <table class='sl-esg-decarbonization-table sl-border-r-1px-medium-charcoal'>
            <thead>
              <tr class='sl-table__row sl-table__row--esg'>
                <td class='sl-w-130px sl-flex-justify-content-end'>
                  <span class='sl-w-100per sl-h-45px'></span>
                </td>
                <td class='sl-w-85px sl-flex-justify-content-end'>
                  <span class='sl-w-100per sl-flex-justify-align-center sl-h-45px'>Total</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-if='!secondaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>$/tCO2e</td>
                <td class='sl-w-85px'>{{ secondaryOffsetTotals.price_per_metric_ton }}</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                <td class='sl-w-130px'>Cost ($)</td>
                <td class='sl-w-85px'>{{ secondaryOffsetTotals.cost }}</td>
              </tr>
              <tr class='sl-table__row sl-table__row--esg sl-h-45px sl-border-b-1px'>
                <td class='sl-w-130px'>tCO2e</td>
                <td class='sl-w-85px'>{{ secondaryOffsetTotals.emission_offset }}</td>
              </tr>
            </tbody>
          </table>
          <div class='sl-esg-overflow-container'>
            <table class='sl-esg-decarbonization-table' id='sl-annual-carbon-offsets-table'>
              <thead>
                <tr class='sl-table__row sl-table__row--esg'>
                  <td v-for='year in years' class='sl-w-7per'>{{ year }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-if='!secondaryLocalOrdinance' class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='(carbonOffset, index) in secondaryCarbonOffsets'>
                    <div>
                      <input @change="updateCarbonOffset(carbonOffset, false, index)"
                            v-model='carbonOffset.price_per_metric_ton'
                            placeholder="$0.00"
                            type='text'
                            class='sl-w-100per'/>
                    </div>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='carbonOffset in secondaryCarbonOffsets' class='sl-w-7per'>
                    <span v-html='carbonOffset.cost'></span>
                  </td>
                </tr>
                <tr class='sl-table__row sl-table__row--esg sl-h-45px'>
                  <td v-for='carbonOffset in secondaryCarbonOffsets' class='sl-w-7per'>{{ carbonOffset.emission_offset }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      baselineMetricId: null,
      baselineMetric: null,
      years: [],
      primaryTotalOffset: null,
      primaryCarbonOffsets: [],
      primaryOffsetTotals: {},
      primaryPathwayName: '',
      primaryLocalOrdinance: false,
      secondaryTotalOffset: null,
      secondaryCarbonOffsets: [],
      secondaryOffsetTotals: {},
      secondaryPathwayName: '',
      secondaryLocalOrdinance: false,
      dataReady: false,
      alertMessage: '',
      primaryOffsetType: 'total',
      secondaryOffsetType: 'total',
      primaryPathwaySelected: false,
      secondaryPathwaySelected: false,
    };
  },

  mounted() {
    this.baselineMetricId = new URLSearchParams(window.location.search).get('baseline_metric_id');
    this.fetchCarbonOffsets();
  },

  methods: {
    async fetchCarbonOffsets() {
      await axios.get('/carbon_offset_values', {
        params: {
          baseline_metric_id: this.baselineMetricId,
        },
      })
        .then((response) => {
          this.baselineMetric = response.data.baseline_metric;
          this.primaryPathwaySelected = response.data.primary_pathway_selected;
          this.secondaryPathwaySelected = response.data.secondary_pathway_selected;
          this.primaryTotalOffset = response.data.primary_total_offset;
          this.primaryCarbonOffsets = response.data.primary_carbon_offsets;
          this.primaryOffsetTotals = response.data.primary_offset_totals;
          this.primaryPathwayName = response.data.primary_pathway_name;
          this.primaryLocalOrdinance = response.data.primary_local_ordinance;
          this.secondaryTotalOffset = response.data.secondary_total_offset;
          this.secondaryCarbonOffsets = response.data.secondary_carbon_offsets;
          this.secondaryOffsetTotals = response.data.secondary_offset_totals;
          this.secondaryPathwayName = response.data.secondary_pathway_name;
          this.secondaryLocalOrdinance = response.data.secondary_local_ordinance;
          this.years = response.data.years;
          this.dataReady = true;
        })
        .catch(() => {
          this.alertMessage = 'Error Fetching Data';
        });
    },

    async updateCarbonOffset(carbonOffset, primary, index = null) {
      await axios.put(`/carbon_offset_values/${carbonOffset.id}`, {
        baseline_metric_id: this.baselineMetricId,
        price_per_metric_ton: this.formatPrice(carbonOffset),
      })
        .then((response) => {
          if (carbonOffset.offset_type === 'total' && primary) {
            this.primaryTotalOffset = response.data.carbon_offset;
          } else if (carbonOffset.offset_type === 'total' && !primary) {
            this.secondaryTotalOffset = response.data.carbon_offset;
          } else if (primary) {
            this.primaryCarbonOffsets[index] = response.data.carbon_offset;
            this.primaryOffsetTotals = response.data.offset_totals;
          } else {
            this.secondaryCarbonOffsets[index] = response.data.carbon_offset;
            this.secondaryOffsetTotals = response.data.offset_totals;
          }
          this.alertMessage = 'Table Saved';
          setTimeout(() => { this.alertMessage = ''; }, 1000);
        })
        .catch(() => {
          this.alertMessage = 'Error Saving Table';
        });
    },

    formatPrice(carbonOffset) {
      return carbonOffset.price_per_metric_ton.replace(/[^\d.-]/g, ''); // Replace non-numerical characters
    },

    async clearAll(primary) {
      const pathwayName = primary ? this.primaryPathwayName : this.secondaryPathwayName;
      if (!window.confirm(`Caution: Are you sure you want to clear all price per metric ton values for ${pathwayName}?`)) return;
      await axios.post('/carbon_offset_values/clear_all', {
        baseline_metric_id: this.baselineMetricId,
        primary,
      })
        .then((response) => {
          if (primary) {
            this.primaryTotalOffset = response.data.total_offset;
            this.primaryCarbonOffsets = response.data.carbon_offsets;
            this.primaryOffsetTotals = response.data.offset_totals;
          } else {
            this.secondaryTotalOffset = response.data.total_offset;
            this.secondaryCarbonOffsets = response.data.carbon_offsets;
            this.secondaryOffsetTotals = response.data.offset_totals;
          }
        });
    },
  },
};
</script>
