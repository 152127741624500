<template>
  <form class="dropzone dz-container dz-clickable sl-psd-dropzone" id="pl_dropzone_project_scope_documents" controller="project_scope_documents" action="/project_scope_documents" enctype="multipart/form-data" accept-charset="UTF-8" data-remote="true" method="post">
    <input name="utf8" type="hidden" value="✓" autocomplete="off">
    <input autocomplete="off" type="hidden" :value="project_scope_id" name="project_scope_document[project_scope_id]" id="project_scope_document_project_scope_id">
    <input type="hidden" name="project_scope_document[client_uploaded]" id="project_scope_document_client_uploaded" value="true" autocomplete="off">
    <input type="hidden" name="project_scope_document[status]" id="project_scope_document_status" value="Review Needed" autocomplete="off">
    <input autocomplete="off" type="hidden" :value="doc" name="project_scope_document[doc]" id="project_scope_document_doc">
    <div class='nd_dz_message_container dz-message' data-dz-message="">
      <div class='nd_dz_top'>
        <document class='nd_dropzone_photo_image figma-icon-medium-charcoal' id='nd_dropzone_photo_medium_charcoal' />
        <document class='nd_dropzone_photo_image figma-icon-blue' id='nd_dropzone_photo_primary_blue' />
        <div class='nd_dz_upload_message'>
          <span>Document Upload</span>
          <p class='nd_dropzone_paragraph'>Click here to browse or drag and drop files here.</p>
          <p class='nd_dropzone_paragraph'>File size must be &lt;500MB</p>
        </div>
      </div>
    </div>
  </form>
  <button id='pl_dropzone_submit_btn' class='whiteBtn submit_btn_not_ready' v-on:click="renderLoadingModal($event)">Upload Files</button>
  <div id="dropzone-container">
    <div class="dropzone-template">
      <div class="dz-remove sl-flex-justify-content-and-align-items-center" data-dz-remove>
        <xdelete class="dropzone-x-image" />
      </div>
      <div class="dropzone-text">
          <span class="dropzone-filename" data-dz-name></span>
          &nbsp;&nbsp;
          |
          &nbsp;&nbsp;
          <span class="otherstuff" data-dz-size></span>
          &nbsp;&nbsp;
          |
          &nbsp;&nbsp;
      </div>
    </div>
  </div>
  <spinner v-if="showLoadingModal"/>
</template>

<script>
import document from '../../../../assets/images/icons/Icon-Single-Doc_Charcoal-Dark-100_24.svg';
import xdelete from '../../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_20.svg';
import spinner from '../SpinningModal.vue';

export default {
  props: ['project_scope_id'],

  components: {
    document,
    xdelete,
    spinner,
  },

  data() {
    return {
      doc: null,
      showLoadingModal: null,
    };
  },
  methods: {
    renderLoadingModal(event) {
      const { target } = event;
      const classes = target.classList;
      if (classes.contains('submit_btn_not_ready')) {
        this.showLoadingModal = false;
      } else {
        this.showLoadingModal = true;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      enable_val_doc_uploader(),
      dropzone_val_doc_icon_changeable();
    });
  },
};
</script>
