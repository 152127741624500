<template>
  <div class="sl-mb-14px sl-sub-header-blue-10">
    Step 2 of 4 | Select all service(s) for estimating pricing
  </div>

  <p class="sl-partner-blue-text">
    Final order selections will be made in step 4.
  </p>

  <!-- TODO: (Paul) -->
  <!-- If agency client, and has commonly ordered services -->
  <!-- <p>
    Commonly ordered services for this account are preselected for convenience
  </p> -->

  <!-- Spinning wheel loader -->
  <div v-if="default_services_column_1.length === 0">
    <div class="sl-flex-justify-align-center" style="width: 100%; height: 300px;">
      <div class="sl-spinning-wheel-50px"></div>
    </div>
  </div>

  <Transition>
  <div v-if="default_services_column_1.length > 0" class="sl-flex-justify-content-space-btwn">
    <!-- column 1 -->
    <div style="width: 48%;">
      <div class="sl-mb-20px" v-for="group in default_services_column_1" :key="group.department" >
        <div class="sl-flex-justify-content-space-btwn sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">{{ group.department }}</div>
        </div>

        <div v-for="service in group.services" :key="service.id">
          <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
            <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
            <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
              <span>{{ service.service_name }}</span>
            </label>
            <span class="checkmark"></span>
          </label>

          <div v-if="service.service_name.includes('Radon')" style="background-color: #FCDBD3;" class="sl-p-all-5px">
            <span class="sl-bold-text">Recommendation: </span>
            Radon testing should be completed for Zone 1 properties with residential units.
          </div>
        </div>
      </div>
    </div>

    <!-- Column 2 -->
    <div style="width: 48%;" class="sl-custom-flex-column-justify-space-btwn">
      <div>
        <div class="sl-mb-20px" v-for="group in default_services_column_2" :key="group.department">
          <div class="sl-flex-only sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div class="sl-paragraph-med-bold">{{ group.department }}</div>
          </div>

          <div v-for="service in group.services" :key="service.id">
            <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
              <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
              <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
                <span>{{ service.service_name }}</span>
              </label>
              <span class="checkmark"></span>
            </label>

            <div v-if="service.service_name.includes('Radon')" style="background-color: #FCDBD3;" class="sl-p-all-5px">
              <span class="sl-bold-text">Recommendation: </span>
              Radon testing should be completed for Zone 1 properties with residential units.
            </div>
          </div>
        </div>

        <!-- Additional Services selected from the pop-up -->
        <div v-if="additional_services.length > 0" class="sl-mb-20px" >
          <div class="sl-flex-only sl-mb-10px sl-border-bottom-2px-charcoal sl-pb-5px">
            <div><span class="sl-paragraph-med-bold">Additional Services</span> (From the popup list)</div>
          </div>

          <div v-for="service in additional_services" :key="service.id">
            <label class='sl-custom-checkbox sl-pt-2px' :name="'checkbox_' + service.id">
              <input type="checkbox" :id="'checkbox_' + service.id" :value="service" v-model="this.passed_in_order_form_data.requested_services_data" />
              <label :for="'checkbox_' + service.id" class="sl-font-size-12px">
                <span>{{ service.service_name }}</span>
              </label>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <!-- Pop-up service list -->
      <ServiceListModal :additional_services="additional_services"
                        :passed_in_order_form_data="passed_in_order_form_data"
                        @update_order_form_data_from_service_list_modal="update_order_form_data_from_service_list_modal" />
    </div>
  </div>
  </Transition>

  <hr/>
  <div class="sl-mtb-10px sl-flex-justify-content-space-btwn">
    <button @click="previous_page" class="sl-simple-outline-btn sl-custom-btn-padding-1">Go Back</button>
    <span v-if="required_fields_missing" class="sl-flex-align-items-center sl-flex-justify-content-end sl-plr-10px sl-required-text"
                                         style="flex-grow: 1;">
      * One or more selections are required
    </span>
    <button @click="next_page" class="sl-generic-blue-background-btn sl-custom-btn-padding-1">Continue</button>
  </div>
</template>

<script>
import axios from 'axios';
import ServiceListModal from './ServiceListModal.vue';

export default {
  props: {
    passed_in_order_form_data: {
      type: Object,
    },
  },

  components: {
    ServiceListModal,
  },

  data() {
    return {
      required_fields_missing: false,
      default_services_column_1: [],
      default_services_column_2: [],
      additional_services: [], // Non-default services that were selected from the popup modal
    };
  },

  async mounted() {
    await axios.get('/project_orders/return_service_list', {
      params: {
        defaults_only: true,
      },
    })
      .then((response) => {
        const services_mid_point = Math.ceil(response.data.service_list.length / 2);
        this.default_services_column_1 = response.data.service_list.slice(0, services_mid_point);
        this.default_services_column_2 = response.data.service_list.slice(services_mid_point, response.data.service_list.length);

        // updates the additional services group on the page
        this.update_additional_services_list(this.passed_in_order_form_data.requested_services_data);
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    // When the user selects a service from the popup modal,
    // this method is called to update the "Additional Services" group on the page
    update_additional_services_list(passed_in_requested_services_data) {
      const temp_all_services = [];

      this.default_services_column_1.forEach((group) => {
        group.services.forEach((service) => {
          temp_all_services.push(service);
        });
      });

      this.default_services_column_2.forEach((group) => {
        group.services.forEach((service) => {
          temp_all_services.push(service);
        });
      });

      // If the service is not in the temp_all_services array, then it is an additional service that was selected from the popup modal
      this.additional_services = passed_in_requested_services_data.filter(
        (service) => temp_all_services.some((temp_service) => temp_service.id === service.id) === false,
      );
    },

    // This method is called from the ServiceListModal component
    update_order_form_data_from_service_list_modal(data) {
      this.$emit('update_order_form_data', data);

      // updates the additional services group on the page
      this.update_additional_services_list(data.requested_services_data);
    },

    previous_page() {
      this.$emit('previous_page');
    },

    next_page() {
      if (this.check_required_fields()) {
        this.required_fields_missing = true;
        return;
      }

      this.$emit('next_page');
    },

    check_required_fields() {
      return this.passed_in_order_form_data.requested_services_data.length === 0;
    },
  },
};
</script>

<style scoped>
  .sl-custom-flex-column-justify-space-btwn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
