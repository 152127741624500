<!-- For CPM, this page is only visible to staff -->
<template>
  <SpinningModal v-if='showLoadingModal' :modalLoadingText="modalLoadingText"/>
  <div class="sl-working-doc-container">
    <!-- Display all bluelynx generated documents -->
    <div class="sl-working-doc-left-col">
      <div class='sl-blue-header'>
        <div>Bluelynx Generated Documents</div>
          <a v-bind:href="'/zip_generators/download_all_bluelynx_generated_docs?project_type={{ project_type }}&project_id=' + project_id">
            <img src="../../assets/Dashboard_Download_Charcoal.png" class="figma-icon-download" width="24" height="24">
          </a>
      </div>
      <div class='sl-working-doc-components-container'>
        <!-- Display Word Draft -->
        <div class='sl-vue-working-doc-generated-docs'>
          <div class='sl-document-card'>
            <template v-if="word_draft">
              <template v-if="word_draft.manual_upload">
                <span style='font-size: 20px; color: #F04C25;'>*</span>
              </template>
              <a v-bind:href="'/projectdocs/' + word_draft.id" class='file-options'>
                <document class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
              </a>
              <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-90per'>Word Writer Draft<br>
                <a href="#" @click="open_history_modal(word_draft.id)">{{ word_draft_date }}  </a> 
                | {{ word_draft_size }} MB                
              </div>
            </template>

            <template v-else >
              <document class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon figma-icon-disabled" />
              <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-100per sl-long-text sl-long-text-disabled'>Word Writer Draft<br>has not been created</div>              
            </template>
          </div>
          <!-- Display Photolog -->
              <div class='sl-document-card'>
                <template v-if="photolog_draft">
                  <template v-if="photolog_draft.manual_upload">
                    <span style='font-size: 20px; color: #F04C25;'>*</span>
                  </template>
                  <a v-bind:href="'/projectdocs/' + photolog_draft.id" class='file-options'>
                    <photo class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
                  </a>
                  <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-90per'>PDF Photolog Draft<br>                                                   
                    <a href="#" @click="open_history_modal(photolog_draft.id)">{{ photolog_draft_date }}  </a>
                    | {{ photolog_draft_size }} MB
                  </div>
                </template>
                <template v-else >
                  <photo class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon figma-icon-disabled" style='width: 32px' />
                  <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-100per sl-long-text sl-long-text-disabled'>PDF Photolog Draft<br>has not been created</div>
                </template>
              </div>
          <!-- Display Site Map & Site Plan RMP ONLY -->
          <template v-if="project_type !== 'cpm'">
            <div class='sl-document-card'>
              <template v-if="site_map">
                <template v-if="site_map.manual_upload">
                  <span style='font-size: 20px; color: #F04C25;'>*</span>
                </template>
                <a v-bind:href="'/projectdocs/' + site_map.id" class='file-options'>                    
                  <xpdf class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
                </a>
                  <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-90per'>Figure 1 Roof Map<br>                                                                                                    
                    <a href="#" @click="open_history_modal(site_map.id)">{{ site_map_date }}  </a>
                    | {{ site_map_size }} MB                    
                  </div>
              </template>
              <template v-else >
                <xpdf class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon figma-icon-disabled" />
                <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-w-100per sl-long-text sl-long-text-disabled'>Roof Map<br>has not been created</div>
              </template>
            </div>
            
          </template>
          
        </div>
          <!-- Vertical Line -->
          <div class='sl-figma-vertical-line'></div>
          <!-- Hide for CPM reports -->
          <!-- Upload Section - Allows uploading of new documents -->
          <div class="sl-working-doc-upload" v-if=" project_type !== 'cpm' ">
            <!-- This partial allows for Bluelynx Docs to be uploaded and overwrite current doc -->
            <genDocUpload
              :projectdoc=word_draft
              :projectdoc_type= projectdoc_type_word_draft
              projectdoc_friendly_type="Word Draft"
              :report_type=project_type
              file_types=".docx"
            />
            
              <hr class='sl-upload-doc-hr'>
              <genDocUpload
                :projectdoc=photolog_draft
                :projectdoc_type= projectdoc_type_photolog
                projectdoc_friendly_type="PDF Photolog"
                :report_type=project_type
              />            
            <!-- Manual upload for site map/site plan RMP only -->              
              <hr class='sl-upload-doc-hr'>
              <genDocUpload
                :projectdoc=photolog_draft
                projectdoc_type= "rmp_site_map_doc"
                projectdoc_friendly_type="Site Map"
                :report_type=project_type
              />                       
          </div>
      </div>
      <div class='sl-bold-text' v-if="project_type !== 'cpm'">
        <span style='font-size: 20px; color: #F04C25;'>*</span>
        Indicates a document has been manually uploaded and may have been modified outside of BlueLynx
      </div>
    </div>

    <!-- Supplemental Documents Upload  -->
    <!-- <template > -->
      <div class="sl-working-doc-right-col">
        <div class='sl-blue-header'>
          <div>Supplemental Documents Upload</div>
          <div>
            <template v-if="project_type === 'rmp'">
              <a v-bind:href="'/zip_generators/download_all_bluelynx_supplemental_docs?doc_type=rmp+sup+working+doc&project_id=' + project_id">
                <img src="../../assets/Dashboard_Download_Charcoal.png" class="figma-icon-download" width="24" height="24">
              </a>
            </template>
            <template v-else>
              <a v-bind:href="'/zip_generators/download_all_bluelynx_supplemental_docs?doc_type=cpm+sup+working+doc&project_id=' + project_id">
                <img src="../../assets/Dashboard_Download_Charcoal.png" class="figma-icon-download" width="24" height="24">
              </a>
            </template>
            <template v-if="supplemental_docs.length > 0">
              <template v-if="project_type === 'rmp'">
                <a v-bind:href="'/projectdocs/delete_all_supplemental_docs?doc_type=rmp+sup+working+doc&project_id=' + project_id" data-confirm="Are you sure you would like to delete all documents?">
                  <trash class="figma-icon-delete-white sl-ml-10px" />
                </a>
              </template>
              <template v-else>
                <a v-bind:href="'/projectdocs/delete_all_supplemental_docs?doc_type=cpm+sup+working+doc&project_id=' + project_id" data-confirm="Are you sure you would like to delete all documents?">
                  <trash class="figma-icon-delete-white sl-ml-10px" />
                </a>
              </template>
            </template>
          </div>
        </div>

        <!-- PCA Supplemental Documents Upload Area -->
        <div class='sl-mtb-5px sl-ml-10px'>
          <div class='sl-bold-text'>Assessors: Please upload all project related documentation (If you are submitting files for a PCA they must be in this format: docx, .jpg, jpeg, .png, .xls, .xlsx, .xlsm, .csv)</div>            
        </div>
        <template v-if="supplemental_docs.length > 0">
          <div class='sl-p-all-5px'>
          <div class='sl-new-figma-checkbox-parent sl-flex-align-items-center'>
            <div class='sl-progress-checkbox'>
            <label for='sl-supp-doc-checkbox-check-all'>
              <input type="checkbox" id="sl-supp-doc-checkbox-check-all" :checked="all_supp_docs_checked" @change="enable_check_all($event)">
              <span class="sl-progress-checkmark sl-cursor-pointer"></span>
            </label>
            </div>
            <p class='sl-pl-5px'><strong>Select All Documents</strong></p>
            <div class='sl-p-all-20px sl-bold-text'>QA/QC Reviewers: Select the PDF files you would like included in the report appendices. Only PDF files can be selected</div>
            </div>
          </div>
        </template>
        <div class="sl-working-doc-components-container">
          <!-- Uploaded documents - displays all uploaded documents -->
          <div class='sl-working-doc-uploaded-docs sortable_area' id='sl-supp-uploaded-docs'>
            <template v-for="(supplemental_doc, index) in supplemental_docs" :key="supplemental_doc.id">
              <div class='sl-document-card sl-checkbox-document-card sl-document-card-pca-supp-doc' :data-project-id="supplemental_doc.id" style='width:95%'>
                <div class='sl-new-figma-checkbox-parent'>
                  <div class='sl-progress-checkbox'>
                    <label :for="`checkbox_${supplemental_doc.id}`">
                      <input type="checkbox" :ref="supplemental_doc.id" class="sl-supp-doc-checkbox" :name="`checkbox_${supplemental_doc.id}`" :id="`checkbox_${supplemental_doc.id}`" :checked="supplemental_doc.include_in_report_assembly" @change="enable_update_pca_supp_checkboxes($event, supplemental_doc.id)">
                      <span class="sl-progress-checkmark sl-cursor-pointer"></span>
                    </label>
                  </div>
                </div>
                <a v-bind:href="'/projectdocs/' + supplemental_doc.id" class='file-options'>
                  <document class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon" />
                </a>
                <div class='sl-drag-and-drop-subtext sl-text-charcoal sl-long-text sl-mt-5px'>
                  {{ supplemental_doc.doc_file_name }}
                  <br>
                  {{ supplemental_doc_formatted_dates[index] }} | {{ supplemental_doc_formatted_sizes_mb[index] }} MB                                    
                </div>
                <a v-bind:href="'/projectdocs/' + supplemental_doc.id" class='file-options' data-confirm="Are you sure you would like to delete this document?" rel="nofollow" data-method="delete">
                  <xdelete class="sl-svg-icon-size-3 figma-icon-delete sl-mt-5px" />
                </a>
              </div>
            </template>

            <template v-if="supplemental_docs.length === 0" >
              <div class='sl-document-card sl-checkbox-document-card'>
                <document class="sl-svg-icon-size-4 sl-charcoal-filter figma-icon figma-icon-disabled" />
                <div class='sl-drag-and-drop-subtext'>Supplemental Documents<br>have not been uploaded</div>
              </div>
              <div class='sl-document-card'></div>
            </template>
            <template v-if="supplemental_docs.length % 2 === 1" >
              <div class='sl-document-card sl-checkbox-document-card'></div>
            </template>
          </div>

          <div class='sl-figma-vertical-line'></div>

          <!-- Dropzone Upload -->
          <div class="sl-working-doc-upload">
            <div class='sl-new-drag-drop'>              
                <cpmSupDocDropzone v-show="project_type === 'cpm'" />              
                <rmpSupDocDropzone v-show="project_type ==='rmp'" />
              <div class='sl-p-all-20px'><b>NOTE:</b><br>Select the boxes for all items to be included in the Report Assembly.</div>
            </div>
          </div>
        </div>
      </div>
    <!-- </template> -->
  </div>

  <hr class='sl-bottom-hr'>

  <div class='sl-report-progress-container'>
    <!-- Display checkbox progress of sections completed -->
    <div class='sl-report-progress-left-column'>
      <template v-if="section_completions.length > 0 && project_type !== 'rmp'" >
        <div class='sl-report-progress-checkboxes'>
          <div class='sl-new-figma-checkbox-parent sl-checkbox-display-flex sl-mr-10px'>
            <div v-for="section_completion in section_completions" :key="section_completion.id" >
              <div class='sl-progress-checkbox'>
                <label :for="`section_${section_completion.section_number}`">
                  <input type="checkbox" :name="`section_${section_completion.section_number}`" :id="`section_${section_completion.section_number}`" :checked="section_completion.completed" disabled="disabled">
                  <span class="sl-progress-checkmark"></span>
                  <div>{{ section_completion.section_number }}.0</div>
                </label>
              </div>
            </div>
          </div>
          <div class='sl-report-progress-description-text'>
            <template v-if="total_completed === total_sections">
              {{total_completed}} of {{total_sections}} Sections are completed. All sections are completed.
            </template>
            <template v-else >
              {{total_completed}} of {{total_sections}} Sections are completed. All sections must be complete to submit report.
            </template>
          </div>
        </div>
      </template>

      <!-- Create Report Draft Button -->
      <template v-if="project_type === 'cpm'">
        <cpmCreateDraft />
      </template>
      <template v-else>
        <rmpCreateDraft />
      </template>

      <!-- Submit Report Button -->
      <template v-if="project_type !== 'rmp'">
        <div class='sl-submit-report sl-mtb-20px sl-display-flex-justify-content-start'>
          <button class='pl-blue-border-and-fill-text-rect-btn sl-doc-submit-report-modal' v-bind:class="(word_draft)?'':'sl-disabled-button'" type='button'>Submit Report</button>
          <div class='sl-report-progress-description-text'>
            All fields in all sections must be completed before report can be submitted.
          </div>
        </div>
      </template>

      <!-- Approval Data Button -->
      
        <div class='sl-submit-report sl-mtb-20px sl-display-flex-justify-content-start'>
          <button class='pl-blue-border-and-fill-text-rect-btn {{ approved_data_btn_disable_class }}' id='sl-doc-approve-data-modal' v-on:click="data_approval_button_handler($event, project_scope_id)" type='button'>
            {{ approved_button_text }}
          </button>
          <div class='sl-report-progress-description-text'>
            <div>{{ approved_data_text }}</div>
            <div v-if='approved_data' class='sl-font-size-14px disabled-font'>{{ `Approved by ${approved_by} on ${approved_date}` }}</div>
            <div v-for="role in project_signatures">
              <div v-if='role.has_signature === false && role.staff_member != null' class='sl-font-color-orange'>
                {{`${role.staff_member.name} (${role.role}) does not have a signature on file - signature will not be automatically added.`}}
                <br><a :href="'/staffs/' + role.staff_member.id + '/edit'" target="_blank">Click here to add their signature to the database</a>
              </div>
              <div v-else-if='role.has_signature === false && role.staff_member === null' class='sl-font-color-orange'>{{ `There is no ${role.role} associated with this project - signature will not be automatically added.` }}</div>
            </div>
          </div>
        </div>
      

      <!-- Reviewer Feedback Button -->
      <div class="sl-submit-report sl-mtb-20px sl-display-flex-justify-content-start">
        <a :href="`/data_item_comments?project_scope_id=${project_scope_id}`" class="pl-blue-border-and-fill-text-rect-btn">
          Reviewer Feedback
        </a>
        <div class='sl-report-progress-description-text'>
          Summary of the comments left by the reviewer on field responses.
        </div>
      </div>
    </div>
    
    
  </div>

  

  <!-- Modal for Submitting Report -->
  <submit />
<photosHistoryModule v-if="show_history" :project_type="project_type" :all_working_docs="all_working_docs" @close-modal='show_history = false;'/>

</template>



<script>
  import axios from 'axios'  
  import submit from "../components/SubmitReportModal.vue"  
  import cpmCreateDraft from "../components/cpm/CreateDraftReportButton.vue"  
  import rmpCreateDraft from "../components/rmp/CreateDraftReportButton.vue"
  import cpmSupDocDropzone from "../components/cpm/SupplementalDocDropzone.vue"
  import rmpSupDocDropzone from "../components/rmp/SupplementalDocDropzone.vue"
  import genDocUpload from "../components/WorkingDocumentsBluelynxGenDocUpload.vue"
  import document from "../../../assets/images/icons/Icon-Single-Doc_Charcoal-Dark-100_24.svg"
  import photo from "../../../assets/images/icons/photo.svg"
  import trash from "../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg"
  import xdelete from '../../../assets/images/icons/Icon-Clear_Charcoal-Dark-100_16.svg';
  import xpdf from "../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Dark-100_24.svg"
  import photosHistoryModule from '../components/PhotosHistoryModal.vue';
  import SpinningModal from '../components/SpinningModal.vue'

  export default {
    components: {
      submit,      
      cpmCreateDraft,      
      rmpCreateDraft,
      cpmSupDocDropzone,
      rmpSupDocDropzone,
      genDocUpload,
      document,
      photo,
      trash,
      xdelete,
      xpdf,
      photosHistoryModule,
      SpinningModal
    },

    data() {
      return {
        project_id: null,
        project_type: null,
        project_number_string: null,
        project_name: null,
        project_address: null,
        word_draft: null,
        word_draft_date: null,
        word_draft_size: null,
        projectdoc_type_word_draft: null,
        projectdoc_type_photolog: null,
        photolog_draft: null,
        photolog_draft_date: null,
        photolog_draft_size: null,
        supplemental_docs: [],
        supplemental_doc_formatted_dates: [],
        supplemental_doc_formatted_sizes_mb: [],
        site_map: null,
        site_map_date: null,
        sime_map_size: null,
        section_completions: [],
        total_sections: 0,
        total_completed: 0,
        approved_data_text: null,
        approved_button_text: null,
        approved_data: null,
        approved_by: null,
        approved_date: null,
        approved_data_btn_disable_class: null,
        project_signatures: [],
        show_history: false,
        all_working_docs: [],
        showLoadingModal: false,
        modalLoadingText: '',
      }
    },

    // Load all project data variables
    async created() {
      const urlParams = new URLSearchParams(window.location.search);
      const project_id = urlParams.get('id');
      const project_type = urlParams.get('project_type');
      this.projectdoc_type_word_draft = project_type + '_word_draft_doc';
      this.projectdoc_type_photolog = project_type + '_pdf_photolog_doc';
      await axios.get(`/${project_type}_data_items/return_report_summary_data`, { 
        params: { 
          project_id: project_id,
          project_type: project_type
        } 
      })
      .then((response) => {
        this.project_id = project_id;
        this.project_type = project_type;
        this.project_scope_id = response.data.project_scope_id;
        this.project_number_string = response.data.project_number_string;
        this.project_name = response.data.project_name;
        this.project_address = response.data.project_address;
        this.word_draft = response.data.word_draft;
        this.word_draft_date = response.data.word_draft_date;        
        this.word_draft_size = response.data.word_draft_size;
        this.photolog_draft = response.data.photolog_draft;
        this.photolog_draft_date = response.data.photolog_draft_date;        
        this.photolog_draft_size = response.data.photolog_draft_size;
        this.supplemental_docs = response.data.supplemental_docs;
        this.supplemental_doc_formatted_dates = response.data.supplemental_doc_formatted_dates;
        this.supplemental_doc_formatted_sizes_mb = response.data.supplemental_doc_formatted_sizes_mb;
        this.site_map = response.data.site_map;
        this.site_map_date = response.data.site_map_date;        
        this.site_map_size = response.data.site_map_size
        this.section_completions = response.data.section_completions;
        this.total_sections = response.data.total_sections;
        this.total_completed = response.data.total_completed;
        this.approved_data_text = response.data.approved_data_text;
        this.approved_button_text = response.data.approved_button_text;
        this.approved_data = response.data.approved_data;
        this.approved_by = response.data.approved_by;
        this.approved_date = response.data.approved_date;
        this.approved_data_btn_disable_class = response.data.approved_data_btn_disable_class;
        this.project_signatures = response.data.project_signatures;
      }).catch((error) => {
        console.log(error);
      });
  },

  computed: {
    all_supp_docs_checked() {
      // set all_supp_docs_checked to true if all supplemental docs are checked
      const checked_arr = [];
      this.supplemental_docs.forEach((supplemental_doc) => {
        if (supplemental_doc.include_in_report_assembly) {
          checked_arr.push(true);
        }
      });
      return checked_arr.length === this.supplemental_docs.length;
    },
  },

  methods: {
    data_approval_button_handler(event, project_scope_id) {
      // Check the current approval status
      let window_confirm_text = 'Rescind data approval?';
      let approving_data = false;
      if (event.target.innerText.toLowerCase() === 'approve data for sitelynx dashboard') {
        window_confirm_text = 'Approve data for SiteLynx Dashboard?';
        approving_data = 'true';
      }

      // Axios post call to set the project scope's approval data to true
      if (window.confirm(window_confirm_text)) {
        if (approving_data) {
          this.modalLoadingText = 'Approving data... Please wait';
        } else {
          this.modalLoadingText = 'Rescinding data approval... Please wait';
        }
        this.showLoadingModal = true;
        axios.post(`/project_scopes/approve_project_scope_data`, {
          project_scope_id,
          approving_data,
        })
          .then((response) => {
            if (response.data.result === 'Successfully updated') {
              window.location.reload(); // for now, reload page
            } else {
              alert(response.data.result);
            }
          })
          .catch((error) => {
            alert('An error has occured. The project data was not approved. '
            + 'Please check your internet connection and try again. '
            + 'If the problem persists, please notify an administrator.');
          });
      }
    },
    enable_update_pca_supp_checkboxes(event, projectdoc_id) {
      // Axios post call to set the supplemental doc's include_in_report_assembly
      axios.post('/projectdocs/update_include_in_report_assembly', {
        projectdoc_id,
        include_in_report_assembly: event.target.checked,
        is_vue_file: true,
      })
        .then((response) => {
          console.log(response.data.result);
        })
        .catch((error) => {
          alert('Projectdoc update encountered an error.');
        });
    },
    enable_check_all(event) {
      // Check all supplemental docs
      this.supplemental_docs.forEach((supplemental_doc) => {
        this.$refs[supplemental_doc.id][0].checked = event.target.checked;
        // Axios post call to set all supplemental docs include_in_report_assembly
        this.enable_update_pca_supp_checkboxes(event, supplemental_doc.id);
      });
    },
    open_history_modal(projectdoc_id) {
      this.show_history = true

      axios.get('/report_datas/vue_working_documents_history', {
        params: { 
          projectdoc_id
        }
      })
        .then((response) => {
          this.show_history = true
          this.all_working_docs = response.data.all_working_docs
        })
        .catch((error) => {
          
        });
    },
  },
};
</script>

<style>
  .file-options:hover {
    color: #F04C25;
    cursor: pointer;
  }
</style>
