<template>
  <div class='sl-flex-justify-content-center sl-pb-10px'>
    <div class='sl-w-500px'>
      <strong class='report-table-instructions'>
        Only describe sites in the surrounding area that are an environmental concern to the subject property.
        Sites of concern include any property that could impact the subject property due to a release of hazardous substances.
        Typically, sites with open releases and situated hydrologically up-gradient are considered sites of concern; however,
        releases from sites situated down- or cross-gradient can migrate onto the subject property if vapor barriers do not exist.
      </strong>
      <br><br>
      <strong class='report-table-instructions'>
        Discuss all NPL sites when the subject property is in the AOC.
      </strong>
      <br><br>
      <strong class='report-table-instructions'>
        For non-NPL sites, consider the following guidelines from ASTM E2600-15 for the release:
      </strong>
      <ul class="instructions-list">
        <li class='report-table-instructions instuctions-list-item'>
          The site is located beyond the AOC (i.e., greater than 528 feet for petroleum hydrocarbons and 1,760 feet for all other chemicals)
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          No preferential pathways exist (such as utility corridors, sewers, storm drains, etc.)
          between the plume and the subject property boundary that may provide a more direct path for vapors to migrate onto the subject property
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          A hydraulic or physical barrier (such as a river) exists between the plume and the subject property;
          this would likely impede migrating vapors to the subject property
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          Direction of groundwater flow; this can only be used for sites situated hydrologically down-gradient or hydrologically cross-gradient;
          never use this for sites situated hydrologically up-gradient to the subject property
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          The presence of impermeable soil type such as clay; careful if you have sand or silt as they are both very permeable
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          Petroleum hydrocarbons: 1/10 mile or 528 feet
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          All other releases (including chlorinated solvents): 1/3 mile or 1,760 feet
        </li>
        <li class='report-table-instructions instuctions-list-item'>
          Regional contamination of hazardous substances, including PFAS is a REC to the subject property unless
          the full extent of the contamination has been identified as off-site AND there is a low likelihood it will impact the subject property in the future.
        </li>
      </ul>
    </div>
  </div>
</template>
