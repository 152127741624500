<template>
  <div class="sl-flex-justify-content-space-btwn">
    <!-- Left Column -->
    <div :style="left_column_width">

      <!-- Client Details (Only show on Page 5 - No edits) -->
      <div class="sl-mb-30px" v-if="current_page > 4">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Client Details</div>
        </div>

        <!-- If no_contact_data is empty, show the current contact data -->
        <div v-if="this.no_contact_data === ''">
          <p>{{ this.order_form_data.client_contact_data.full_name }}</p>

          <p>
            {{ this.order_form_data.client_contact_data.address }} {{ this.order_form_data.client_contact_data.address_2 }}
            <br>
            {{ this.order_form_data.client_contact_data.city }},
            {{ this.order_form_data.client_contact_data.state }} {{ this.order_form_data.client_contact_data.zip }}
          </p>

          <p>
            {{ this.order_form_data.client_contact_data.email }}
          </p>

          <p>
            {{ this.order_form_data.client_contact_data.phone }}
          </p>
        </div>

        <div v-else>
          <p>{{ this.no_contact_data }}</p>
        </div>
      </div>

      <!-- Site Location & Property Type -->
      <div class="sl-mb-30px" v-if="current_page > 1">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Site Location & Property Type</div>
        </div>

        <p>{{ this.order_form_data.property_location.name }}</p>
        <p>
          <span v-if="this.order_form_data.property_location.address">{{ this.order_form_data.property_location.address }} <br></span>
          <span v-if="this.order_form_data.property_location.city !== ''">{{ this.order_form_data.property_location.city }}, </span>
          {{ this.order_form_data.property_location.state }} {{ this.order_form_data.property_location.zip }}
        </p>

        <p>
          {{ this.order_form_data.property_location.county }}
        </p>

        <p>
          <span v-if="this.order_form_data.property_location.radon_zone === '1'">
            <span class="sl-bold-text" style="color: #F04C25;">Radon Zone: 1</span>
            <div class="sl-flex-only sl-pt-5px">
              <span class="sl-plr-5px">•</span>
              <span> Radon testing should be done for Zone 1 properties with residential units.</span>
            </div>
          </span>
          <span v-else>
            Radon Zone: {{ this.order_form_data.property_location.radon_zone === '' ? 'N/A' : this.order_form_data.property_location.radon_zone}}
          </span>
        </p>

        <p>
          APN: {{ this.order_form_data.property_location.apn }}
        </p>

        <!-- <p>
          <span>
            Seismic Zone: {{ this.order_form_data.property_location.seismic_zone === '' ? '–' : this.order_form_data.property_location.seismic_zone}}
          </span>
        </p> -->

        <p>Primary:
          {{ primary_property_type }}
        </p>

        <p v-if="this.order_form_data.property_type_data.secondary_property_type !== ''">
          Secondary: {{ secondary_property_type }}
        </p>
      </div>

      <!-- Additional Details -->
      <div class="sl-mb-30px" v-if="current_page > 3">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Additional Details</div>
        </div>

        <p v-if="this.order_form_data.loan_data.client_reference_loan_number !== ''">
          Reference Number:
          {{ this.order_form_data.loan_data.client_reference_loan_number}}
        </p>

        <p>
          Purpose of Order: {{ purpose_of_order }}
          <span v-if="this.order_form_data.loan_data.describe_purpose_of_order !== ''">– {{ this.order_form_data.loan_data.describe_purpose_of_order }}</span>
        </p>

        <p v-if="this.order_form_data.loan_data.loan_term !== ''">
          Loan Term: {{  this.order_form_data.loan_data.loan_term }}
        </p>

        <p v-if="this.order_form_data.loan_data.agency_type !== ''">
          Agency: {{ agency_type }}
        </p>

        <p v-if="this.order_form_data.loan_data.loan_type !== ''">
          Loan Type: {{ loan_type }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.year_built !== ''">
          Year Built:
          {{ this.order_form_data.property_site_details_data.year_built }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.num_of_buildings !== ''">
          Buildings/Site:
          {{ this.order_form_data.property_site_details_data.num_of_buildings }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.building_sq_ft !== ''">
          Building SF:
          {{ this.order_form_data.property_site_details_data.building_sq_ft }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.num_of_floors !== ''">
          Floors/Building:
          {{ this.order_form_data.property_site_details_data.num_of_floors }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.total_units_rooms_sites !== ''">
          Total Units/Rooms/Sites/Beds:
          {{ this.order_form_data.property_site_details_data.num_of_units_rooms_sites}}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.ground_floor_units !== ''">
          Total Ground Units:
          {{ this.order_form_data.property_site_details_data.ground_floor_units }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.num_of_tenants !== ''">
          Total Tenants:
          {{ this.order_form_data.property_site_details_data.num_of_tenants }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.land_acreage !== ''">
          Land Acreage:
          {{ this.order_form_data.property_site_details_data.land_acreage }}
        </p>

        <p v-if="this.order_form_data.property_site_details_data.estimated_construction_budget !== ''">
          Est. Const. Budget:
          {{ this.order_form_data.property_site_details_data.estimated_construction_budget }}
        </p>

        <p v-if="this.order_form_data.property_type_data.property_development_type !== ''">
          Development Type:
          {{ development_type }}
        </p>
      </div>

      <div class="sl-mb-30px">
        <span v-if="current_page < 5" class="sl-paragraph-med-bold sl-partner-blue-text">
          Click the “Go Back” button to edit previously entered information.
        </span>
        <div v-else class="sl-cursor-pointer sl-flex-align-items-center" @click="previous_page">
          <topArrow style="transform: rotate(-90deg); overflow: visible;"/>
          <span class="sl-ml-10px" style="color: var(--medium-dark-charcoal)">Go back to edit information entered on previous screens.</span>
        </div>
      </div>
    </div>

    <!-- Right Column -->
    <div :style="right_column_width" v-if="right_column_display">
      <!-- Invoice Details -->
      <div class="sl-mb-30px">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Invoice Details</div>
          <editIcon class="sl-cursor-pointer" @click="show_pop_up(2)" />
        </div>

        <div v-if="show_invoice_details">
          <p>{{ this.order_form_data.invoice_data.first_name }} {{ this.order_form_data.invoice_data.last_name }}</p>

          <p>
            {{ this.order_form_data.invoice_data.company_name }}
          </p>

          <p>
            {{ this.order_form_data.invoice_data.address }} {{ this.order_form_data.invoice_data.address_2 }}
            <br>
            {{ this.order_form_data.invoice_data.city }}
            {{ this.order_form_data.invoice_data.state }}
            {{ this.order_form_data.invoice_data.zip }}
          </p>

          <p>
            {{ this.order_form_data.invoice_data.email }}
          </p>

          <p v-if="this.order_form_data.invoice_data.do_not_contact">
            <strong>Notice <span style="color: #F04C25;">Do Not Contact</span></strong>
          </p>
        </div>

        <p v-else>
          Click edit icon above if invoice contact differs from the client contact.
        </p>
      </div>

      <!-- Report Addressee -->
      <div class="sl-mb-30px">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Report Addressee</div>
          <editIcon class="sl-cursor-pointer" @click="show_pop_up(1)" />
        </div>

        <div v-if="show_report_addressee">
          <p>{{ this.order_form_data.report_addressee_data.first_name }} {{ this.order_form_data.report_addressee_data.last_name }}</p>

          <p>
            {{ this.order_form_data.report_addressee_data.title }}
          </p>

          <p>
            {{ this.order_form_data.invoice_data.company_name }}
          </p>

          <p>
            {{ this.order_form_data.report_addressee_data.address }} {{ this.order_form_data.report_addressee_data.address_2 }}
            <br>
            {{ this.order_form_data.report_addressee_data.city }}
            {{ this.order_form_data.report_addressee_data.state }}
            {{ this.order_form_data.report_addressee_data.zip }}
          </p>

          <p>
            {{ this.order_form_data.invoice_data.email }}
          </p>
        </div>

        <p v-else>
          Click edit icon above if report addressee differs from the client contact.
        </p>
      </div>

      <!-- Site & Property Info Contact Details -->
      <div class="sl-mb-30px">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Site & Property Contact(s)</div>
          <editIcon class="sl-cursor-pointer" @click="show_pop_up(3)" />
        </div>

        <div v-if="show_site_contact || show_property_information_contact">
          <!-- Site Contact -->
          <div v-if="show_site_contact">
            <p class="sl-sub-header-charcoal-light-40">Site Contact</p>

            <p>
              {{ this.order_form_data.site_contact_data.first_name }} {{ this.order_form_data.site_contact_data.last_name }}
            </p>

            <p>
              {{ this.order_form_data.site_contact_data.phone }}
            </p>

            <p>
              {{ this.order_form_data.site_contact_data.email }}
            </p>

            <p v-if="this.order_form_data.site_contact_data.do_not_contact">
              <strong>Notice <span style="color: #F04C25;">Do Not Contact</span></strong>
            </p>

            <p v-if="this.order_form_data.site_contact_data.relationship_to_property !== ''">
              Relationship to Property: <br>
              {{ this.order_form_data.site_contact_data.relationship_to_property }}
            </p>

            <p v-if="this.order_form_data.site_contact_data.site_visit_request_dates !== ''">
              Site Visit Date(s): <br>
              {{ this.order_form_data.site_contact_data.site_visit_request_dates }}
            </p>
          </div>

          <!-- Property Information Contact -->
          <div v-if="show_property_information_contact">
            <p class="sl-sub-header-charcoal-light-40">Property Information Contact</p>

            <p>
              {{ this.order_form_data.property_information_contact_data.first_name }} {{ this.order_form_data.property_information_contact_data.last_name }}
            </p>

            <p>
              {{ this.order_form_data.property_information_contact_data.phone }}
            </p>

            <p>
              {{ this.order_form_data.property_information_contact_data.email }}
            </p>

            <p v-if="this.order_form_data.property_information_contact_data.do_not_contact">
              <strong>Notice <span style="color: #F04C25;">Do Not Contact</span></strong>
            </p>

            <p v-if="this.order_form_data.property_information_contact_data.relationship_to_property !== ''">
              Relationship to Property: <br>
              {{ this.order_form_data.property_information_contact_data.relationship_to_property }}
            </p>

            <p v-if="this.order_form_data.property_information_contact_data.intended_use !== ''">
              Intended Use: <br>
              {{ this.order_form_data.property_information_contact_data.intended_use }}
            </p>

            <p v-if="this.order_form_data.property_information_contact_data.intended_users !== ''">
              Intended Users: <br>
              {{ this.order_form_data.property_information_contact_data.intended_users }}
            </p>
          </div>
        </div>

        <p v-else>
          Click edit icon above if contact(s) differs from the client contact.
        </p>
      </div>

      <!-- Email CC -->
      <div class="sl-mb-30px">
        <div class="sl-flex-justify-content-space-btwn sl-border-bottom-2px-charcoal sl-pb-5px">
          <div class="sl-paragraph-med-bold">Email CCs</div>
          <editIcon class="sl-cursor-pointer" @click="show_pop_up(4)" />
        </div>

        <p v-if="order_form_data.email_cc_data === ''">
          Click the edit icon above to send email notifications to additional team members, aside from the client contact.
        </p>

        <p v-else class="sl-flex-only">
          <div>CC:</div>
          <div class="sl-pl-5px">
            <span v-for="email in order_form_data.email_cc_data.split(',')" :key="email">{{ email }}<br></span>
          </div>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint max-lines: ["error", {"max": 500, "skipComments": true, "skipBlankLines": true}] */
import editIcon from '../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import topArrow from '../../../../assets/images/icons/Icon-To-Top_Charcoal-Dark-100_20.svg';

export default {
  props: {
    order_form_data: {
      type: Object,
    },
    current_page: {
      type: Number,
    },
    order_summary_view: {
      type: Object,
    },
    add_update_data_modal: {
      type: Object,
    },
    dropdown_selections: {
      type: Object,
    },
    no_contact_data: {
      type: String,
    },
  },

  components: {
    editIcon,
    topArrow,
  },

  methods: {
    previous_page() {
      this.$emit('previous_page');
    },

    // calls the parent method (show_pop_up in OrderForm.vue) to show the pop up
    show_pop_up(section) {
      this.$emit('show_pop_up', section);
    },
  },

  computed: {
    // show_invoice_details, show_report_addressee, etc are duplicated intentionally to be auto-called by vue computed
    // returns true if there is any data in the invoice_data object
    show_invoice_details() {
      let result = false;

      Object.keys(this.order_form_data.invoice_data).forEach((key) => {
        if (this.order_form_data.invoice_data[key] !== '') {
          result = true;
        }
      });

      if (result) {
        this.order_form_data.invoice_data_entered = true;
      } else {
        this.order_form_data.invoice_data_entered = false;
      }

      return result;
    },

    show_report_addressee() {
      let result = false;

      Object.keys(this.order_form_data.report_addressee_data).forEach((key) => {
        if (this.order_form_data.report_addressee_data[key] !== '') {
          result = true;
        }
      });

      if (result) {
        this.order_form_data.report_addressee_data_entered = true;
      } else {
        this.order_form_data.report_addressee_data_entered = false;
      }

      return result;
    },

    show_site_contact() {
      let result = false;

      Object.keys(this.order_form_data.site_contact_data).forEach((key) => {
        if (this.order_form_data.site_contact_data[key] !== '') {
          result = true;
        }
      });

      if (result) {
        this.order_form_data.site_contact_data_entered = true;
      } else {
        this.order_form_data.site_contact_data_entered = false;
      }

      return result;
    },

    show_property_information_contact() {
      let result = false;

      Object.keys(this.order_form_data.property_information_contact_data).forEach((key) => {
        if (this.order_form_data.property_information_contact_data[key] !== '') {
          result = true;
        }
      });

      if (result) {
        this.order_form_data.property_information_contact_data_entered = true;
      } else {
        this.order_form_data.property_information_contact_data_entered = false;
      }

      return result;
    },

    development_type() {
      return this.order_form_data.property_type_data.property_development_type;
    },

    primary_property_type() {
      return this.order_form_data.property_type_data.primary_property_type;
    },

    secondary_property_type() {
      return this.order_form_data.property_type_data.secondary_property_type;
    },

    purpose_of_order() {
      return this.order_form_data.loan_data.purpose_of_order;
    },

    agency_type() {
      return this.order_form_data.loan_data.agency_type;
    },

    loan_type() {
      return this.order_form_data.loan_data.loan_type;
    },

    // Show the right column only if current page is 5
    right_column_display() {
      if (this.current_page === 5) {
        this.order_summary_view.right_column_visible = true;
        return true;
      }
      this.order_summary_view.right_column_visible = false;
      return false;
    },

    // Sets the width of the left column depending on right_column_display if it's displayed or not
    left_column_width() {
      return this.right_column_display ? 'width: 48%' : 'width: 100%';
    },

    // Sets the width of the right column depending on right_column_display if it's displayed or not
    right_column_width() {
      return this.right_column_display ? 'width: 48%' : 'width: 100%';
    },
  },
};
</script>
