<template>
  <div class="sl-flex-only" style="margin: 0 auto; max-width: 1500px; min-height: 600px;">
    <!-- Col 1 -->
    <div class="sl-p-all-10px sl-w-40per" >
      <div style="border: 1px solid gray; width: fit-content;" class="sl-p-all-10px">
        <h1>Portfolio Bluelynx Instructions</h1>
        <p>When a new set of instructions is made for a portfolio, a pop-up will appear for all the BlueLynx projects. </p>
        <p>Users must accept that they read the instructions before they can continue the writer. Currently affects ALL users.</p>
        <p>If the current set of instructions is updated OR a new set of instructions is made,
          the pop-up will re-appear and the user must accept it again to continue. </p>
      </div>

      <div class="sl-mt-20px">
        <input placeholder="Search By Portfolio Name.."
               type="text" W
               :required="true"
               v-model="search_params"
               style="width: 100%; max-width: 300px;"
               class="sl-mr-10px">
        <button @click="search" :disabled="disabled_search">Search</button>
      </div>

      <!-- render search result -->
      <div class="sl-mt-20px">
        <table class="sl-simple-table-1">
          <thead>
            <tr>
              <th>Portfolio Name</th>
              <th>Client</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="initial_load">
              <td colspan="3">Showing the 10 most recently updated portfolios with instructions..</td>
            </tr>
            <tr v-for="portfolio in portfolios" :key="portfolio.id">
              <td>
                <a :href="/portfolios/ + portfolio.id" target="_blank">{{ portfolio.name }}</a>
              </td>
              <td>
                {{ portfolio.client }}
              </td>
              <td>
                <button @click="view_instructions(portfolio.id, portfolio.name)">View Instructions</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Col 2 -->
    <div class="sl-p-all-10px sl-w-60per" style="border-left: 5px solid #005199;">
      <div v-if="currently_viewing_portfolio_id">

        <div class="sl-mt-20px">
          <button @click="view_existing" class="sl-mr-10px" :disabled="view_existing_instructions">View Existing Instructions</button>
          <button @click="show_inputs" :disabled="start_new_instructions">Start New Instructions</button>
        </div>

        <!-- Current instructions list -->
        <div v-if="view_existing_instructions" class="sl-mt-20px">
          <div v-if="portfolio_bluelynx_instructions.length === 0">No existing instructions found</div>
          <div v-else>
            <p>Viewing existing instructions for Portfolio: <strong>{{ currently_viewing_portfolio_name }}</strong></p>
            <br>

            <!-- create table -->
            <table class="sl-simple-table-1">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Unique ID</th>
                  <th>Title</th>
                  <th>Body</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  <th>Updated By</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="instruction in portfolio_bluelynx_instructions" :key="instruction.id">
                  <td><button @click="edit_record(instruction)">Edit</button></td>
                  <td><strong>{{ instruction.deprecated ? '' : 'Currently Set' }}</strong></td>
                  <td>{{ instruction.id }}</td>
                  <td>{{ instruction.title.substring(0, 10) + '...'}}</td>
                  <td>{{ instruction.body.substring(0, 10) + '...' }}</td>
                  <td>{{ instruction.created_by }}</td>
                  <td>{{ instruction.created_at }}</td>
                  <td>{{ instruction.updated_by }}</td>
                  <td>{{ instruction.updated_at }}</td>
                </tr>
              </tbody>
            </table>

            <br>
            <br>

            <!-- editing record -->
            <div v-if="editing_record">
              <h1>Editing Instructions Unique ID: {{ editing_record_id }}</h1>
              <br>
              <div>Title Text</div>
              <textarea style="width: 100%; height: 40px;" v-model="edit_title_text"></textarea>
              <br>
              <br>
              <div>Body Text</div>
              <textarea style="width: 100%; height: 800px;" v-model="edit_body_text"></textarea>

              <div class="sl-mt-10px sl-text-align-center">
                <button :disabled="disabled_update" @click="update_record">Update</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Create new instructions -->
        <div v-if="start_new_instructions" class="sl-mt-20px">
          <h1>Start new instructions</h1>
          <p>Instructions will be created for the portfolio: <strong>{{ currently_viewing_portfolio_name }}</strong></p>

          <div class="sl-mt-20px">
            <div>Title Text</div>
            <textarea style="width: 100%; height: 40px;" v-model="new_title_text"></textarea>
          </div>

          <div class="sl-mt-20px">
            <div>Body Text</div>
            <textarea style="width: 100%; height: 800px;" v-model="new_body_text"></textarea>
          </div>

          <div class="sl-mt-10px sl-text-align-center">
            <button :disabled="disabled_create" @click="create_new_record">Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      disabled_update: false,
      disabled_create: false,
      disabled_search: false,
      search_params: '',
      portfolios: [],
      portfolio_bluelynx_instructions: [],
      currently_viewing_portfolio_id: null,
      view_existing_instructions: false,
      start_new_instructions: false,
      new_title_text: '',
      new_body_text: '',
      edit_title_text: '',
      edit_body_text: '',
      editing_record_id: null,
      editing_record: false,
      initial_load: true,
    };
  },

  async created() {
    const currentObj = this;

    // get most recently updated portfolios with instructions
    axios.get('/portfolio_bluelynx_instructions/return_most_recently_updated_instructions')
      .then((response) => {
        currentObj.portfolios = response.data.portfolios;
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    update_record() {
      const currentObj = this;

      if (currentObj.edit_title_text === '' || currentObj.edit_body_text === '') {
        return;
      }

      currentObj.disabled_update = true;

      axios.post('/portfolio_bluelynx_instructions/ajax_update', {
        id: currentObj.editing_record_id,
        title: currentObj.edit_title_text,
        body: currentObj.edit_body_text,
      })
        .then((response) => {
          if (response.data.result === 'success') {
            alert('Instructions updated');

            currentObj.editing_record = false;
            currentObj.editing_record_id = null;
            currentObj.edit_title_text = '';
            currentObj.edit_body_text = '';

            // refresh the list
            this.view_instructions(currentObj.currently_viewing_portfolio_id, currentObj.currently_viewing_portfolio_name);
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          currentObj.disabled_update = false;
        });
    },

    edit_record(record) {
      this.editing_record = true;
      this.editing_record_id = record.id;
      this.edit_title_text = record.title;
      this.edit_body_text = record.body;
    },

    create_new_record() {
      const currentObj = this;

      if (currentObj.new_title_text === '' || currentObj.new_body_text === '') {
        return;
      }

      currentObj.disabled_create = true;

      axios.post('/portfolio_bluelynx_instructions/ajax_create', {
        portfolio_id: currentObj.currently_viewing_portfolio_id,
        title: currentObj.new_title_text,
        body: currentObj.new_body_text,
      })
        .then((response) => {
          if (response.data.result === 'success') {
            alert('New instructions created');

            currentObj.new_title_text = '';
            currentObj.new_body_text = '';
            currentObj.view_existing_instructions = true;
            currentObj.start_new_instructions = false;

            // refresh the list
            this.view_instructions(currentObj.currently_viewing_portfolio_id, currentObj.currently_viewing_portfolio_name);
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          currentObj.disabled_create = false;
        });
    },

    view_existing() {
      this.view_existing_instructions = true;
      this.start_new_instructions = false;
    },

    show_inputs() {
      this.start_new_instructions = true;
      this.view_existing_instructions = false;
    },

    view_instructions(portfolio_id, portfolio_name) {
      const currentObj = this;
      currentObj.currently_viewing_portfolio_id = portfolio_id;
      currentObj.currently_viewing_portfolio_name = portfolio_name;

      axios.get('/portfolio_bluelynx_instructions/return_portfolio_bluelynx_instructions', {
        params: {
          portfolio_id,
        },
      })
        .then((response) => {
          currentObj.portfolio_bluelynx_instructions = response.data.portfolio_bluelynx_instructions || [];
          currentObj.view_existing_instructions = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    search() {
      const currentObj = this;

      if (currentObj.search_params === '') {
        return;
      }

      currentObj.disabled_search = true;
      currentObj.initial_load = false;

      axios.get('/portfolio_bluelynx_instructions/ajax_search', {
        params: {
          search_params: currentObj.search_params,
        },
      })
        .then((response) => {
          currentObj.portfolios = response.data.portfolios;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          setTimeout(() => {
            currentObj.disabled_search = false;
          }, 1000);
        });
    },
  },
};
</script>

<style>

</style>
