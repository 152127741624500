<template>
  <!-- Service Estimates and Selections -->
  <table class="sl-custom-table-1 sl-mt-10px">
    <thead>
      <tr>
        <th>Service(s) Requested</th>
        <th>Turnaround Time</th>
        <th>Estimate</th>
      </tr>
    </thead>

    <tbody>
      <!-- Renders when Calculate estimates called -->
      <template v-for="service in passed_in_order_form_data.service_estimates_and_selections_data" :key="service.id">
        <tr>
          <td>
            <div>{{ service.department_name }}</div>
            <div class="sl-bold-text">{{ service.service_name }}</div>
          </td>
          <td>
            <div class="sl-bold-text">{{ set_turnaround(service) }}</div>
          </td>
          <td>
            <div class="sl-bold-text">{{ set_estimate(service) }}</div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>

  <div class="sl-flex-justify-content-space-btwn sl-mtb-10px">
    <div class='sl-pl-10px sl-w-50per'>
      <div class="sl-mtb-5px sl-bold-text sl-partner-blue-text">Order Notes</div>
      <textarea type="text"
                name="order_notes"
                class="sl-input-type-1"
                @input="autoFitTextAreaOnInput"
                v-model="this.passed_in_order_form_data.order_notes_data"
                style="height: 90px; resize: none; box-sizing: initial;"
                maxlength="200"
                placeholder="Click here to include additional notes."></textarea>
    </div>

    <div class="sl-pr-10px sl-w-50per sl-text-align-right">
      <div class="sl-mtb-5px sl-bold-text sl-partner-blue-text">Estimated Grand Total *</div>
      <div class="sl-total-cost-text">{{ this.passed_in_order_form_data.service_total }}</div>

      <button class="sl-generic-blue-background-btn sl-custom-btn-padding-2" @click="submitOrder">Submit Order</button>
      <TermsAndConditions
        :service_estimates_and_selections_data="passed_in_order_form_data.service_estimates_and_selections_data"
      /> <!-- Only for applicable clients -->
    </div>
  </div>

  <!-- confirmation pop-up -->
  <confirmationPopUp v-if="show_confirmation" :text_1="confirmation_text_1" :text_2="confirmation_text_2" />

  <!-- Overlay -->
  <div v-if="show_overlay" class='sl-general-overlay'></div>
</template>

<script>
import axios from 'axios';
import TermsAndConditions from './TermsAndConditions.vue';
import confirmationPopUp from './confirmationPopUp.vue';

export default {
  components: {
    TermsAndConditions,
    confirmationPopUp,
  },

  props: {
    passed_in_order_form_data: {
      type: Object,
    },
  },

  data() {
    return {
      show_confirmation: false,
      confirmation_text_1: '',
      confirmation_text_2: '',
      show_overlay: false,
    };
  },

  methods: {
    submitOrder() {
      const urlParams = new URLSearchParams(window.location.search);
      const project_order_id = urlParams.get('project_order_id');
      this.show_overlay = true;

      /* eslint-disable max-len */
      axios.post('/project_orders/create_project', {
        order_form_data: this.passed_in_order_form_data,
        project_order_id,
      })
        .then((response) => {
          this.show_confirmation = true;
          this.confirmation_text_1 = 'Thank you for submitting this order. An order confirmation summary will be sent to your inbox. A Partner representative will be in touch within one business day.';
          this.confirmation_text_2 = `ID: ${response.data.project_id}`;

          setTimeout(() => {
            window.location.href = `/projects/${response.data.project_id}`;
          }, 5000);
        })
        .catch((error) => {
          alert(`An error occurred: ${error}`);
        })
        .finally(() => {
          this.show_overlay = false;
        });
    },
    /* eslint-enable max-len */

    set_turnaround(service) {
      if (service.selection === 'price_1') {
        return '15 BD';
      } if (service.selection === 'price_2') {
        return '10 BD';
      } if (service.selection === 'price_3') {
        return 'Rush';
      }

      return 'N/A';
    },

    set_estimate(service) {
      let estimate = service[service.selection];

      if (estimate !== 'RFP') {
        estimate = `$${estimate}`;
      }

      return estimate;
    },

    // Expand a textarea to its content as data is input
    autoFitTextAreaOnInput(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
  },
};
</script>

<style scoped>
  .sl-custom-table-1 {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 2px solid var(--medium-charcoal);

    thead {
      border-top: 2px solid var(--primary-blue);
      border-bottom: 2px solid var(--primary-blue);

      th {
        padding: 5px 10px;
        font-weight: bold;
      }

      th:nth-child(1) {
        text-align: justify;
      }

      th:nth-child(2) {
        text-align: center;
      }

      th:nth-child(3) {
        text-align: right;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--medium-charcoal);

        td {
          padding: 10px;
        }

        td:nth-child(1) {
          text-align: justify;
        }

        td:nth-child(2) {
          text-align: center;
        }

        td:nth-child(3) {
          text-align: right;
        }
      }
    }
  }
</style>
