<template>
  <div class="sl-confirm-background-1">
    <div class="sl-confirm-content-1">
      <div class="sl-flex-justify-content-center">
        <div class="sl-pr-5px">
          <label class="sl-custom-radio sl-mr-5px" style="display: inline;">
            <input type="radio" checked="checked">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="sl-font-size-14px sl-mr-20px">
          {{ text_1 }}
        </div>
        <div class="sl-font-size-14px sl-bold-text sl-white-space-no-wrap">
          {{ text_2 }}
        </div>
      </div>

      <div class="sl-font-size-14px sl-mt-20px sl-text-align-center">
        You will be redirected in a few seconds..
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text_1: {
      type: String,
      default: '',
    },

    text_2: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
  .sl-confirm-background-1 {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.1);
  }

  .sl-confirm-content-1 {
    background-color: var(--green-20);
    padding: 20px;
    border: 1px solid var(--green-100);
    max-width: 600px;
    margin: 10% auto;

    .sl-custom-radio {
      pointer-events: none;
      cursor: initial;

      input:checked ~ .checkmark {
          background-color: var(--green-100);
      }
    }
  }
</style>
