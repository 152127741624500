<template>
  <br>
  <SpinningModal v-if='showLoadingModal'/>
  <div v-if="dataReady">
    <ReportTableInstructions v-if="showInstructionsModal && instructionsAvailable"
      :tableName='tableName'
      :tableTitle='title'
      @close-modal='closeInstructions'
      @import-complete='importComplete'
      @delete-all='deleteAll'
    />
    <ReportTableFields v-if="reportFields.length"
      :projectId="projectId"
      :fields="reportFields"
      @update-field="updateReportField"
    />
    <div :class='alignmentClass()'>
      <div class='sl-pr-30px'>
        <div class='sl-flex-justify-content-space-btwn sl-ptb-10px' id='report-table-header'>
          <div class='sl-min-w-200px sl-flex-align-items-center'>
            <div v-if='instructionsAvailable' title='Instructions and Import'>
              <infoIcon  @click='showInstructionsModal=true;' class='sl-cursor-pointer figma-icon-link'/>
            </div>
            <infoIconDisabled v-else/>
            <button @click='create()' class='sl-simple-outline-btn sl-mlr-10px'>Add Rows</button>
            <input type='number' min='1' :max='maxRowsAvailable()' v-model='rowsToAdd'
                  class='sl-border-1px-medium-charcoal sl-w-60px sl-pl-5px sl-ptb-5px'
                  id='row_number'/>
            <span v-if='alertMessage' class='sl-ml-20px sl-min-w-85px sl-partner-blue-text sl-bold-text'>{{ alertMessage }}</span>
            <span v-else class='sl-ml-20px sl-min-w-85px' style='color: red;'>{{ errorMessage }}</span>
          </div>
        </div>
        <h2 class='sl-static-h2-style sl-text-align-left' id='report-table-title'>
          <span class='sl-ml-10px'>{{ title }}</span>
        </h2>
        <div id='sc-td-height-vue'>
          <table border='1' bordercolor='#E0E0E0' cellpadding='1' cellspacing='1'>
            <thead class='sl-h-32px'>
              <tr>
                <th class='sl-min-w-30px report-table-header-cell'></th>
                <th v-if='sortable' class='sl-min-w-30px report-table-header-cell'></th>
                <th class='sl-min-w-30px report-table-header-cell'></th>
                <th v-for='(header, index) in tableHeaders' class='report-table-header-cell sl-plr-10px'>{{ header }}</th>
              </tr>
            </thead>
            <tbody id='pl_table_body'>
              <tr v-for='(row, rowIndex) in tableData' valign='top' :id=row.id>
                <td class='sl-text-align-center sl-pt-10px sl-bold-text' style='color: #333E47'>{{ row.sort_order + 1 }}.</td>
                <td v-if='sortable' class='sl-cursor-pointer sortable-handle'>
                  <handle/>
                </td>
                <td class='sl-text-align-center sl-pt-5px'>
                    <deleteIcon v-if="!isDisabledCell(row, rowIndex, 'delete_column')" @click='deleteRow(row, rowIndex)'
                                class='figma-icon-delete links-center sl-mt-3px'
                                transform='scale(0.7)'/>
                </td>
                <td class='sl-w-100px' v-for='(column, index) in columns'>
                  <!-- Textarea-->
                  <div v-if="columnTypes[index] === 'textarea'">
                    <textarea v-model='row[column]'
                              @input="autoFitTextAreaOnInput"
                              :id="`${row.id}_${column}`"
                              :disabled="isDisabledCell(row, rowIndex, column)"
                              :class="{'hidden':isHiddenCell(column)}"
                              rows='2' :cols='columnWidths[index]'>
                    </textarea>
                    <!-- Lookups -->
                    <div class='sl-flex-justify-content-center sl-w-100per'>
                      <div class='sl-text-align-center pl_custom_default_choice sl-max-w-160px'>
                        <div v-for='lookup in lookups[index]' @click='updateCellWithLookup(row, rowIndex, column, lookup)'>
                          <span @mouseover="lookupAddHover($event)" @mouseleave="lookupRemoveHover($event)">{{ lookup }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Display, using a disabled textarea -->
                  <textarea v-else-if="columnTypes[index] === 'display'"
                            v-model='row[column]'
                            :id="`${row.id}_${column}`"
                            :disabled="true"
                            rows='2' :cols='columnWidths[index]'>
                  </textarea>

                  <!-- Number Input -->
                  <input v-else-if="columnTypes[index] === 'number'"
                        v-model='row[column]' type='number'
                        @input="numberCellInput(rowIndex, column)"
                        :id="`${row.id}_${column}`"
                        class='sl-h-42px sl-w-100px'
                        :disabled="isDisabledCell(row, rowIndex, column)"
                        />

                  <!-- Yes/No -->
                  <div v-else-if="columnTypes[index] === 'yes_no'" class='can-toggle sl-ptb-5px sl-w-135px'>
                    <input :id="`${row.id}_${column}`" class='sc-toggle-buttons' type='checkbox'
                          :checked="row[column]==1" @change="updateSlider(rowIndex, column)"
                          :disabled="isDisabledCell(row, rowIndex, column)"/>
                    <label :for="`${row.id}_${column}`">
                      <div class='can-toggle__switch' data-checked='Yes' data-unchecked='No'></div>
                    </label>
                  </div>

                  <!-- Select -->
                  <div v-else-if="columnTypes[index] === 'select'" class='sl-w-200px sl-flex-justify-align-center'>
                    <select :id="`${row.id}_${column}`"
                            v-model='row[column]'
                            :disabled="isDisabledCell(row, rowIndex, column)"
                            class='sl-w-175px sl-h-28px sl-mt-10px'>
                      <option v-for='lookup in lookups[index]' :value='lookup'>{{ lookup }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <ReportTableTotals :tableName='tableName' :tableData='tableData'/>
            </tbody>
          </table>
        </div>
        <div class='sl-mt-20px sl-flex-justify-content-space-btwn'>
          <div>
            <button @click='update()' class='sl-simple-outline-btn sl-mr-5px sl-w-100px'>Save</button>
            |
            <a @click='cancel()' class='sl-ml-5px sl-blue-link'>Return To Section</a>
          </div>
          <button @click='deleteAll()' class='sl-simple-outline-btn-warning sl-w-100px'>Delete All</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint max-lines: off */
/* eslint complexity: off */
import axios from 'axios';
import Sortable from 'sortablejs';
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import infoIcon from '../../../../assets/images/icons/information_circle_icon.svg';
import infoIconDisabled from '../../../../assets/images/icons/information_circle_icon_disabled.svg';
import SpinningModal from '../../components/SpinningModal.vue';
import handle from '../../../../assets/images/icons/handle.svg';
import ReportTableInstructions from './ReportTableInstructions.vue';
import ReportTableFields from './ReportTableFields.vue';
import ReportTableTotals from './ReportTableTotals.vue';

export default {
  components: {
    SpinningModal,
    ReportTableInstructions,
    ReportTableFields,
    ReportTableTotals,
    deleteIcon,
    handle,
    infoIcon,
    infoIconDisabled,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectId: urlParams.get('project_id'),
      reportType: null,
      baseUrl: null,
      tableName: urlParams.get('the_group'),
      title: '',
      tableHeaders: [],
      columns: [],
      columnTypes: [],
      columnWidths: [],
      tableData: [],
      lookups: [],
      reportFields: [],
      dataItems: [],
      sortable: false,
      maxRows: null,
      rowsToAdd: 1,
      showLoadingModal: false,
      alertMessage: '',
      errorMessage: '',
      alignment: 'left',
      instructionsAvailable: false,
      showInstructionsModal: false,
      section: urlParams.get('section') || 1,
      subsection: urlParams.get('subsection') || 1,
      user: null,
      dataReady: false,
    };
  },

  mounted() {
    this.setReportType();
    this.fetchTableData();
    this.autoFitTextAreasOnPageLoad();
  },

  methods: {
    setReportType() {
      if (window.location.pathname.includes('pca_report_tables')) {
        this.reportType = 'pca';
        this.baseUrl = '/pca_report_tables';
      } else if (window.location.pathname.includes('big_esa_report_tables')) {
        this.reportType = 'esa';
        this.baseUrl = '/big_esa_report_tables';
      } else if (window.location.pathname.includes('esa_report_tables')) {
        this.reportType = 'esa';
        this.baseUrl = '/esa_report_tables';
      }
    },

    async fetchTableData() {
      axios.get(`${this.baseUrl}/edit_table`, {
        params: {
          project_id: this.projectId,
          table_name: this.tableName,
        },
      })
        .then((response) => {
          this.tableHeaders = response.data.report_table.constants.table_headers;
          this.title = response.data.report_table.constants.title;
          this.columns = response.data.report_table.constants.columns;
          this.columnTypes = response.data.report_table.constants.column_types;
          this.columnWidths = response.data.report_table.constants.column_widths;
          this.maxRows = response.data.report_table.constants.max_rows;
          this.sortable = response.data.report_table.constants.sortable;
          this.tableData = response.data.report_table.table_data;
          this.lookups = response.data.report_table.lookups;
          this.reportFields = response.data.report_fields || [];
          this.dataItems = response.data.data_items || [];
          this.alignment = response.data.report_table.constants.alignment;
          this.instructionsAvailable = response.data.report_table.constants.instructions_available;
          this.user = response.data.user;
          this.removeHiddenColumns();
          this.dataReady = true;
          setTimeout(() => {
            if (this.sortable) { this.setSortable(); }
          }, 500);
          if (this.title === 'Site Visit Personnel') {
            this.setDefaultYes();
          }
        })
        .catch(() => {
          this.errorMessage = 'Error loading table data';
        });
    },

    async create(rowsToAdd = null) {
      if (rowsToAdd) {
        this.rowsToAdd = rowsToAdd;
      }
      if ((this.tableData.length + this.rowsToAdd) > this.maxRows) {
        alert(`The maximum number of rows allowed is ${this.maxRows}.`);
        return;
      }
      axios.post(`${this.baseUrl}/create_new_rows`, null, {
        params: {
          project_id: this.projectId,
          table_name: this.tableName,
          rows: this.rowsToAdd,
        },
      })
        .then((response) => {
          this.tableData = this.tableData.concat(response.data.new_rows);
          this.rowsToAdd = 1;
          if (this.title === 'Site Visit Personnel') {
            this.setDefaultYes();
          }
        })
        .catch(() => {
          this.errorMessage = 'Error creating rows';
        });
    },

    async update() {
      this.showLoadingModal = true;
      axios.put(`${this.baseUrl}/update_table`, {
        project_id: this.projectId,
        table_name: this.tableName,
        table_data: this.tableData,
      })
        .then(() => {
          setTimeout(() => {
            this.showLoadingModal = false;
            this.alertMessage = 'Table Saved';
          }, 200);
          setTimeout(() => { this.alertMessage = ''; }, 2500);
        })
        .catch(() => {
          this.showLoadingModal = false;
          this.errorMessage = 'Error saving table';
        });
    },

    async deleteRow(row, index) {
      if (this.isRowEmpty(row) || window.confirm('Are you sure you want to delete this row?')) {
        await axios.post(`${this.baseUrl}/destroy_row`, {
          id: row.id,
          project_id: this.projectId,
          table_name: this.tableName,
          table_data: this.tableData,
        })
          .then(() => {
            this.tableData.splice(index, 1);
            this.updateSortOrder();
          })
          .catch(() => {
            this.errorMessage = 'Error deleting row';
          });
      }
    },

    async deleteAll() {
      if (!window.confirm('Are you sure you want to delete all rows?')) {
        return;
      }
      await axios.post(`${this.baseUrl}/destroy_all_rows`, {
        project_id: this.projectId,
        table_name: this.tableName,
      })
        .then(() => {
          this.tableData = [];
        })
        .catch(() => {
          this.errorMessage = 'Error deleting all rows';
        });
    },

    // Update optional fields above the report table,
    // Currently only yes/no fields and only used by the pca building summary table
    async updateReportField(index, data) {
      this.reportFields[index].data = data;
      await axios.put('/pca_report_tables/update_report_table_field', {
        project_id: this.projectId,
        field_name: this.reportFields[index].name,
        data,
      })
        .catch(() => {
          this.alertMeassage = 'Error updating report field';
        });
    },

    // Return back to the writer/preview page
    cancel() {
      if (!window.confirm('Return to main page? You will lose any unsaved changes.')) { return; }

      let url;
      if (this.reportType === 'pca') {
        url = `/pca_report_tables/group_cancel?id=0&project_id=${this.projectId}&table_section=${this.subsection}`;
      } else {
        url = `${this.baseUrl}/group_cancel?id=0&project_id=${this.projectId}&table_section=${this.tableName}`;
      }
      window.location.href = url;
    },

    setSortable() {
      const el = document.getElementById('pl_table_body');
      Sortable.create(el, {
        handle: '.sortable-handle',
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: () => {
          this.updateSortOrder();
        },
      });
    },

    // Loop through elements in the table and update the sort_order field
    updateSortOrder() {
      const el = document.getElementById('pl_table_body');
      let index = 0;
      for (let row of el.children) { // eslint-disable-line no-restricted-syntax, prefer-const
        const htmlRow = this.tableData.find((r) => r.id == row.id); // eslint-disable-line eqeqeq
        if (htmlRow) {
          htmlRow.sort_order = index;
          index++;
        }
      }
    },

    maxRowsAvailable() {
      return this.maxRows - this.tableData?.length;
    },

    // Return flex alignment class based on alignment prop
    alignmentClass() {
      let alignmentClass = 'sl-flex-justify-content-start';
      if (this.alignment === 'center') {
        alignmentClass = 'sl-flex-justify-content-center';
      } else if (this.alignment === 'right') {
        alignmentClass = 'sl-flex-justify-content-end';
      }
      return alignmentClass;
    },

    // When a user clicks a lookup, update the cell with the lookup text
    updateCellWithLookup(row, rowIndex, column, lookup) {
      // Do not update the cell with lookup text if the cell is disabled
      if (this.isDisabledCell(row, rowIndex, column)) { return; }
      const tableCell = this.tableData[rowIndex][column];
      if (tableCell?.length > 0 && !tableCell?.includes(lookup)) {
        this.tableData[rowIndex][column] = `${tableCell}, ${lookup}`;
      } else if (!tableCell?.includes(lookup)) {
        this.tableData[rowIndex][column] = lookup;
      }
    },

    lookupAddHover(event) {
      const textarea = event.target.parentElement.parentElement.parentElement.parentElement.firstElementChild;
      textarea.classList.add('pl-custom-shadow');
    },

    lookupRemoveHover(event) {
      const textarea = event.target.parentElement.parentElement.parentElement.parentElement.firstElementChild;
      textarea.classList.remove('pl-custom-shadow');
    },

    closeInstructions() {
      this.showInstructionsModal = false;
    },

    updateSlider(rowIndex, column) {
      this.tableData[rowIndex][column] = this.tableData[rowIndex][column] === '1' ? '0' : '1';
    },

    setDefaultYes() {
      this.tableData.forEach((row) => {
        this.columns.forEach((column, index) => {
          if (this.columnTypes[index] === 'yes_no' && row[column] === null) {
            const row_value = row;
            row_value[column] = '1';
          }
        });
      });
    },

    importComplete() {
      this.showInstructionsModal = false;
      this.fetchTableData();
    },

    // Expand text areas to fit their content on page load
    autoFitTextAreasOnPageLoad() {
      // Delay to allow the table to render before adjusting the textareas
      setTimeout(() => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
          const el = textarea;
          el.style.height = 'auto';
          el.style.height = `${textarea.scrollHeight}px`;
        });
      }, 300);
    },

    // Expand a textarea to its content as data is input
    autoFitTextAreaOnInput(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },

    // Called when a number input is changed, update another column based on the input
    numberCellInput(rowIndex, columnIndex) {
      const column = columnIndex.replace('_column', '');
      if (this.tableName === 'Unit Types' && ['b', 'c'].includes(column)) {
        const quantity = this.tableData[rowIndex].b_column;
        const area = this.tableData[rowIndex].c_column;
        this.tableData[rowIndex].d_column = quantity * area;
      }
    },

    // Add code here to disable inputs as needed
    isDisabledCell(row, rowIndex, columnIndex) {
      const column = columnIndex.replace('_column', '');
      const permission = this.user.permission.toLowerCase();

      // Table specific logic
      switch (this.tableName) {
      case 'adjacent property':
        // Disable columns g, h, i, j, k if the f column is not selected as 'Yes'
        if (row.f_column !== '1' && ['g', 'h', 'i', 'j', 'k'].includes(column)) {
          return true;
        }
        break;
      case 'esa subject property':
        // Disable columns e, f, g, h, i if the d column is not selected as 'Yes'
        if (row.d_column !== '1' && ['e', 'f', 'g', 'h', 'i'].includes(column)) {
          return true;
        }
        break;
      case 'radius report data':
        // Disable columns a, c, and delete for the first 19 rows if the user is not an Admin
        if (!permission.includes('admin') && ['a', 'c', 'delete'].includes(column) && rowIndex < 19) {
          return true;
        }
        break;
      case 'sites of concern':
        // Disable columns g, h, i, j, k columns if the f column is not selected as 'Yes'
        if (row.f_column !== '1' && ['g', 'h', 'i', 'j', 'k'].includes(column)) {
          return true;
        }
        break;
      default:
        return false;
      }
      return false;
    },

    // Return true if the table cell should be hidden
    isHiddenCell(columnIndex) {
      const column = columnIndex.replace('_column', '');
      // Table specific logic
      switch (this.tableName) {
      case 'pca building summary':
        if ((column === 'b' && this.reportFields[0].data === 'yes')
            || (column === 'd' && this.reportFields[1].data === 'yes')
            || (column === 'e' && this.reportFields[2].data === 'yes')) {
          return true;
        }
        break;
      default:
        return false;
      }
      return false;
    },

    // This is called when the table is loaded to remove any columns that need to be hidden
    removeHiddenColumns() {
      if (this.tableName === 'pca building summary' && this.dataItems[0] !== 'self-storage') {
        this.tableHeaders.splice(5, 1);
        this.columns.splice(5, 1);
      }
    },

    // Return true if the table row is empty
    isRowEmpty(row) {
      let empty = true;
      const ignoreColumns = ['id', 'project_id', 'sort_order', 'created_at', 'deprecated', 'name', 'updated_at'];
      for (let i = 0; i < Object.keys(row).length; i++) {
        const key = Object.keys(row)[i];
        const value = row[Object.keys(row)[i]];
        // If the column is not in the ignore list and the value is not null or empty, the row is not empty
        if (!ignoreColumns.includes(key) && value !== null && value !== '') {
          empty = false;
          break;
        }
      }
      return empty;
    },
  },
};
</script>
<style scoped>
  .sortable-ghost {
    opacity: 0.4;
  }
  textarea[disabled] {
    color: #333E47;

  }
  .hidden {
    opacity: 0;
  }
  .hidden {
    opacity: 0;
  }
</style>
