<template>
  <br>
  <br>
  <div class='sl-text-align-center sl-ptb-10px'>
      <strong v-html="project_general_header"></strong>
  </div>
  <div class='sl-flex-justify-content-space-btwn sl-mt-20px'>
    <!-- Left Sidebar -->
    <div class='sl-mt-30px sl-w-300px sl-mr-40px'>
      <div class='sl-flex-column sl-font-size-16px sl-overflow-wrap-anywhere'>
        <a v-for="espm_meter in espm_meters"
           v-on:click=fetchConsumptionData(espm_meter.id)
           class="sl-mb-20px sl-blue-link"
           :class="{ 'side-nav-current-tab': espm_meter.id === selected_meter.id }">
          {{ espm_meter.name }}
        </a>
      </div>
    </div>

    <!-- Main Content -->
    <div v-if="selected_meter" class='sl-flex-justify-content-center sl-w-100per'>
      <div>
        <div class='pl-flex-space-between pl-section-header-bar'>
          <div class='sl-font-size-14px'>{{ selected_meter.meter_type }}<span class='sl-mlr-10px'>&#8212</span>{{ selected_meter.unit_of_measure }}</div>
        </div>
        <table border="0" bordercolor="#E0E0E0" cellspacing="1" class='sl-w-100per' style="border-collapse: collapse;">
          <thead>
            <tr class='sl-h-37px'>
              <td class='sl-w-150px sl-border-lr-2px-light-charcoal sl-border-b-2px-blue sl-text-align-center '><strong>Start Date</strong></td>
              <td class='sl-w-150px sl-border-lr-2px-light-charcoal sl-border-b-2px-blue sl-text-align-center '><strong>End Date</strong></td>
              <td class='sl-w-150px sl-border-lr-2px-light-charcoal sl-border-b-2px-blue sl-text-align-center '><strong>Usage</strong></td>
              <td class='sl-w-150px sl-border-lr-2px-light-charcoal sl-border-b-2px-blue sl-text-align-center '><strong>Cost</strong></td>
            </tr>
          </thead>
          <tbody id='pl_table_body'>
            <tr class="sl-h-37px" v-for="(data, index) in consumption_datas"
                :key="data.id"
                :class="{ 'list-line-odd': index % 2 === 0,
                          'list-line-even': index % 2 === 1 }">
              <td class='sl-w-150px sl-border-2px-light-charcoal sl-text-align-center'>{{ data.start_date }}</td>
              <td class='sl-w-150px sl-border-2px-light-charcoal sl-text-align-center'>{{ data.end_date }}</td>
              <td class='sl-w-150px sl-border-2px-light-charcoal sl-text-align-center'>{{ data.usage }}</td>
              <td class='sl-w-150px sl-border-2px-light-charcoal sl-text-align-center'>{{ data.cost }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class='sl-w-300px sl-ml-40px'></div><!-- Right Side container to keep table centered -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selected_meter: null,
      consumption_datas: null,
      espm_meters: null,
      project_general_header: null,
    };
  },

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const meter_id = urlParams.get('meter_id');
    this.fetchConsumptionData(meter_id);
  },

  methods: {
    async fetchConsumptionData(meter_id) {
      const urlParams = new URLSearchParams(window.location.search);
      const baseline_metric_id = urlParams.get('baseline_metric_id');

      await axios.get('/espm_properties/return_raw_data', {
        params: {
          baseline_metric_id,
          meter_id,
        },
      })
        .then((response) => {
          this.selected_meter = response.data.meter;
          this.consumption_datas = response.data.consumption_datas;
          this.espm_meters = response.data.espm_meters;
          this.project_general_header = response.data.project_general_header;
        })
        .then(() => {
          this.updateURL(baseline_metric_id, this.selected_meter.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Update the url params (meter id) after fetching new data
    updateURL(baseline_metric_id, meter_id) {
      const params = new URLSearchParams();
      params.set('baseline_metric_id', baseline_metric_id);
      params.set('meter_id', meter_id);
      history.replaceState(null, null, `?${params.toString()}`);
    },
  },

};
</script>

<style>
</style>
