<template>
  <div class='sl-flex-justify-content-center sl-h-15px'>
    <span v-if='alertMessage' class='sl-ml-20px sl-min-w-85px sl-partner-blue-text sl-bold-text'>{{ alertMessage }}</span>
    <span v-else class='sl-ml-20px sl-min-w-85px' style='color: red;'>{{ errorMessage }}</span>
  </div>
  <ExcelDropzone
    @upload-complete='uploadComplete()'
    @upload-error='uploadError()'
    :formUrl="'/esg_excel_imports/import_carbon_proforma_projects'"
    :title="'Upload your Carbon Proforma Projects'"
    :maxFiles="100"
    :parallelUploads="100"/>

</template>
<script>
import ExcelDropzone from '../../components/ExcelDropzone.vue';

export default {
  components: {
    ExcelDropzone,
  },

  data() {
    return {
      alertMessage: '',
      errorMessage: '',
    };
  },

  methods: {
    uploadComplete() {
      this.alertMessage = 'Upload complete';
      setTimeout(() => {
        this.alertMessage = '';
      }, 3000);
    },

    uploadError() {
      this.errorMessage = 'Upload error';
    },
  },
};
</script>
